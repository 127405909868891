import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import api from "../../api/api";
import InputField from "../../Components/InputField";
import QrCode from "../../Components/QrCode/QrCode";
import classNames from "../../hooks/classNames";
import { fetchData, fetchDataSuccess } from "../../Slice/userSlice";
import { useAppDispatch } from "../../hooks/redux-hooks";
import { loginValidationSchema, registerValidationSchema } from "./schemas";

import style from "./AuthPage.module.scss";

import logoIcon from "../../assets/New/Icons/newLogo.svg";

interface AuthFormFields {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword?: string;
}

interface AuthPageProps {
  pageVariant?: "register" | "login";
}

const AuthPage: React.FC<AuthPageProps> = ({ pageVariant = "login" }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isRegister = pageVariant === "register";

  const validationSchema = isRegister
    ? registerValidationSchema
    : loginValidationSchema;

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm<AuthFormFields>({
    resolver: yupResolver<any>(validationSchema),
  });

  useEffect(() => {
    reset();
  }, [pageVariant]);

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    password,
  }: AuthFormFields) => {
    if (!isRegister) {
      dispatch(fetchData({ email, password, navigate }));
      return;
    }

    try {
      const response = await api.post("/user/auth/email/signup", {
        email: email,
        first_name: firstName,
        last_name: lastName,
        password: password,
      });

      if (response.data) {
        dispatch(fetchDataSuccess(response.data));
        navigate("/");
      } else {
        navigate("/auth/login");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.logoWrapper}>
        <img src={logoIcon} alt="Logo" className={style.logo} />
        <span className={style.logoText}>School Hack</span>
      </div>
      <div
        className={classNames(
          style.loginPage,
          isRegister && style.registerPage
        )}
      >
        <form
          className={classNames(style.formCard, !isRegister && style.loginForm)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={style.InputFieldContainer}>
            <h2 className={style.heading}>
              {isRegister ? "Register" : "Login"}
            </h2>
            {isRegister && (
              <div className={style.pair}>
                <div className={style.inputWrapper}>
                  <InputField
                    type="text"
                    placeholder="First Name"
                    register={register("firstName")}
                  />
                  {errors?.firstName && (
                    <span className={style.errorMessage}>
                      {errors.firstName.message}
                    </span>
                  )}
                </div>
                <div className={style.inputWrapper}>
                  <InputField
                    type="text"
                    placeholder="Last Name"
                    register={register("lastName")}
                  />
                  {errors?.lastName && (
                    <span className={style.errorMessage}>
                      {errors.lastName.message}
                    </span>
                  )}
                </div>
              </div>
            )}
            <div className={style.inputWrapper}>
              <InputField
                type="email"
                placeholder="Email Address"
                register={register("email")}
              />
              {errors?.email && (
                <span className={style.errorMessage}>
                  {errors.email.message}
                </span>
              )}
            </div>
            <div className={style.inputWrapper}>
              <InputField
                type="password"
                placeholder="Password"
                register={register("password")}
              />
              {errors?.password && (
                <span className={style.errorMessage}>
                  {errors.password.message}
                </span>
              )}
            </div>
            {isRegister && (
              <>
                <div className={style.inputWrapper}>
                  <InputField
                    type="password"
                    placeholder="Confirm Password"
                    register={register("confirmPassword")}
                  />
                  {errors?.confirmPassword && (
                    <span className={style.errorMessage}>
                      {errors.confirmPassword.message}
                    </span>
                  )}
                </div>
              </>
            )}
          </div>
          <button className={style.authButton}>
            {isRegister ? "Sign Up" : "Sign In"}
          </button>
          <p className={style.signUp}>
            {isRegister ? "Already have an account?" : "Don't have an account?"}
            <button
              type="button"
              onClick={() => {
                navigate(isRegister ? "/auth/login" : "/auth/register");
              }}
              className={style.signUpButton}
            >
              {isRegister ? "Sign in" : "Sign up"}
            </button>
          </p>
        </form>
        {!isRegister && <QrCode />}
      </div>
    </div>
  );
};

export default AuthPage;
