import { Dispatch, FC, SetStateAction } from "react";
import { createPortal } from "react-dom";

import useEscapeKey from "../../hooks/useEscape";
import classNames from "../../hooks/classNames";

import style from "./CompanyLinking.module.scss";

import loadingIcon from "../../assets/Icons/loader.svg";
import closeIcon from "../../assets/Icons/close.svg";

interface CompanyLinkingModalProps {
  loading: boolean;
  isLinked: boolean;
  name: string;
  logo_url: string;
  code: string;
  error: string | null;
  setModalOpened: Dispatch<SetStateAction<boolean>>;
}

const CompanyLinkingModal: FC<CompanyLinkingModalProps> = ({
  loading,
  isLinked,
  error,
  name,
  logo_url,
  code,
  setModalOpened,
}) => {
  useEscapeKey(closeModal);

  function closeModal() {
    setModalOpened(false);
  }

  const modalContent = (
    <div className={style.modal}>
      {!loading && isLinked && (name || error) && (
        <button className={style.closeBtn} onClick={closeModal}>
          <img src={closeIcon} alt="Close" />
        </button>
      )}

      <div className={classNames(style.wrapper, { [style.loading]: loading })}>
        {loading || (!name && !error) ? (
          <img
            src={loadingIcon}
            alt="Loading..."
            className={style.loadingImg}
          />
        ) : (
          <>
            {isLinked && !error ? (
              <>
                <img
                  className={style.imageLogo}
                  height="66px"
                  src={logo_url}
                  alt="Company logo"
                />
                <p className={style.boldLabel}>Name of the Institute</p>
                <p className={style.normalLabel}>{name}</p>
                <p className={style.boldLabel}>Institute Code</p>
                <p className={style.boldLabelLast}>{code}</p>

                <p className={style.successMessage}>
                  Congratulations! You have successfully linked your institute
                </p>
                <span onClick={closeModal} className={style.button}>
                  Continue
                </span>
              </>
            ) : (
              <>
                <p className={style.errorMessage}>
                  Sorry you are unable to link this Company Code or it does not
                  exist
                </p>
                <span onClick={closeModal} className={style.button}>
                  Try again
                </span>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );

  return createPortal(
    <div className={style.backdrop} onClick={closeModal}>
      {modalContent}
    </div>,
    document.body
  );
};

export default CompanyLinkingModal;
