import { FC } from "react";

import SkeletonWrapper from "../../../../Components/SkeletonComponent";

const LeaderSkeleton: FC = () => (
  <SkeletonWrapper width="100%" height="90px" viewBox="0 0 100% 90">
    <circle cx="55" cy="45" r="30" />
    <rect x="95" y="36" rx="10" ry="10" width="80" height="18" />
  </SkeletonWrapper>
);

export default LeaderSkeleton;
