import { FC, ReactNode } from "react";
import { createPortal } from "react-dom";

import classNames from "../../hooks/classNames";
import useEscapeKey from "../../hooks/useEscape";

import style from "./Modal.module.scss";

import close from "../../assets/Icons/close.svg";

interface ModalProps {
  children: ReactNode;
  closeModal: () => void;
  styles?: any;
}

const Modal: FC<ModalProps> = ({ children, closeModal, styles }) => {
  useEscapeKey(closeModal);

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) closeModal();
  };

  return createPortal(
    <div className={style.overlay} onClick={handleOutsideClick} id={"modal"}>
      <div className={classNames(style.modal, styles)}>
        <button className={style.closeBtn} onClick={closeModal}>
          <img src={close} alt="Close" />
        </button>
        {children}
      </div>
    </div>,
    document.body.querySelector("#rootContainer"),
  );
};

export default Modal;
