import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { GAME_API } from "../../../../api/requests";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux-hooks";
import {
  clearLeaderBoard,
  clearLeadersError,
  getGameResults,
  setCurrentGame,
  setCurrentQuestion,
  setInvitedGameWithModal,
  setOwnerInvitedGame,
} from "../../../../Slice/gameSlice";
import ModalQuiz from "../Modals/ModalQuiz/ModalQuiz";
import classNames from "../../../../hooks/classNames";
import ScoreModal from "../ScoreModal/ScoreModal";
import LeaderSkeleton from "./LeaderSkeleton";

import style from "./LeaderBoard.module.scss";

import defaultUserImage from "../../../../assets/Icons/profileLogo.jpg";
import CrownIcon from "../../../../assets/Icons/crown.svg";

export const QUIZ_LEADERBOARD_LIMIT = 10;
const clapper = "\u{1F389}";

interface GameLeaderBoardProps {
  isHistoryPage?: boolean;
}

const GameLeaderBoard: React.FC<GameLeaderBoardProps> = ({ isHistoryPage }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [params, setSearchParams] = useSearchParams();
  const gameId = params.get("gameId");
  const quizId = params.get("quizId");

  const currentGame = useAppSelector((state) => state.game.currentGame);
  const results = useAppSelector((state) => state.game.leaders);
  const hasMoreLeaders = useAppSelector((state) => state.game.hasMoreLeaders);
  const leadersError = useAppSelector((state) => state.game.leadersError);

  const participants = results.slice(3);

  useEffect(() => {
    if (!gameId) {
      if (currentGame?._id) {
        setSearchParams({ gameId: currentGame._id });
      } else {
        navigate("/game/multi");
      }
    }
    if (!quizId) {
      return navigate("/game/multi");
    }
    if (!currentGame?._id) getCurrentGame();

    return () => {
      if (isHistoryPage) dispatch(setCurrentGame(null));
    };
  }, []);

  useEffect(() => {
    if (isHistoryPage && leadersError) {
      dispatch(clearLeadersError());
      navigate(`/game/multi/history/results?gameId=${gameId}`);
      return;
    }
  }, [results]);

  const onClickClosePage = () => {
    dispatch(setCurrentGame(null));
    dispatch(setCurrentQuestion(null));
    dispatch(setInvitedGameWithModal(null));
    dispatch(clearLeaderBoard());
    if (isHistoryPage) {
      navigate(`/game/multi/history/results?gameId=${gameId}`);
      return;
    }
    navigate("/game/multi");
  };

  const getCurrentGame = async () => {
    const game = await GAME_API.getGameById(gameId);
    dispatch(setCurrentGame(game));
    dispatch(setOwnerInvitedGame(game));
    if (game && game.status === "ready" && !results?.length) {
      dispatch(getGameResults({ quiz_id: quizId, game: { _id: game._id } }));
    }
  };

  const onClickBackToLobby = () => {
    dispatch(setCurrentQuestion(null));
    dispatch(setInvitedGameWithModal(null));
    dispatch(clearLeaderBoard());
    if (isHistoryPage) {
      navigate(`/game/multi/history/results?gameId=${gameId}`);
      return;
    }

    navigate(`/game/multi/room?gameId=${currentGame._id}`);
  };

  if (!results.length || !currentGame) {
    return <ScoreModal />;
  }

  return (
    <ModalQuiz closeModal={onClickClosePage} isHistoryPage={isHistoryPage}>
      <div
        className={classNames(
          style.container,
          isHistoryPage && style.history,
          participants.length === 0 && style.empty
        )}
      >
        <div className={style.congratulationsWrapper}>
          <div className={style.congratulationsHeading}>
            <h3>Congratulations {clapper}</h3>
            {currentGame?.winner_takes_all ? (
              <p>
                {results[0].user_meta.first_name} Earned{" "}
                {currentGame.per_game_cost * results.length} Credits
              </p>
            ) : (
              <p>{results[0].user_meta.first_name} is the winner</p>
            )}
          </div>

          <div className={style.podiumWrapper}>
            <div
              className={classNames(style.podiumSecond, style.podiumPosition)}
            >
              {results.length >= 2 && (
                <div className={style.podiumCircle}>
                  <img
                    src={
                      results[1].user_meta.profile_image_url || defaultUserImage
                    }
                    alt="User"
                    className={style.podiumUserImage}
                  />
                  <div className={style.userPlaceWrapper}>
                    <span className={style.userPlace}>2</span>
                  </div>
                </div>
              )}
              <div className={style.podiumUserDetails}>
                {results.length >= 2 && (
                  <>
                    <p className={style.podiumUsername}>
                      {results[1].user_meta?.first_name || "User"}
                    </p>
                    <p className={style.podiumUserPoints}>
                      {results[1].user_score}/{results[1].total_score}
                    </p>
                  </>
                )}
              </div>
            </div>
            <div
              className={classNames(style.podiumFirst, style.podiumPosition)}
            >
              <img src={CrownIcon} alt="Crown" className={style.crownIcon} />
              <div className={style.podiumCircle}>
                <img
                  src={
                    results[0].user_meta.profile_image_url || defaultUserImage
                  }
                  alt="User"
                  className={style.podiumUserImage}
                />
                <div className={style.userPlaceWrapper}>
                  <span className={style.userPlace}>1</span>
                </div>
              </div>
              <div className={style.podiumUserDetails}>
                <p className={style.podiumUsername}>
                  {results[0].user_meta?.first_name || "John Doe"}
                </p>
                {currentGame?.winner_takes_all && (
                  <p className={style.podiumUserCredits}>
                    {currentGame.per_game_cost * results.length} Credits
                  </p>
                )}
                <p className={style.podiumUserPoints}>
                  {results[0].user_score}/{results[0].total_score}
                </p>
              </div>
            </div>
            <div
              className={classNames(style.podiumThird, style.podiumPosition)}
            >
              {results.length >= 3 && (
                <div className={style.podiumCircle}>
                  <img
                    src={
                      results[2].user_meta.profile_image_url || defaultUserImage
                    }
                    alt="User"
                    className={style.podiumUserImage}
                  />
                  <div className={style.userPlaceWrapper}>
                    <span className={style.userPlace}>3</span>
                  </div>
                </div>
              )}
              <div className={style.podiumUserDetails}>
                {results.length >= 3 && (
                  <>
                    <p className={style.podiumUsername}>
                      {results[2].user_meta?.first_name || "User"}
                    </p>
                    <p className={style.podiumUserPoints}>
                      {results[2].user_score}/{results[2].total_score}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <button className={style.backToLobbyBtn} onClick={onClickBackToLobby}>
            {isHistoryPage ? "Back to History" : "Back to Lobby"}
          </button>
        </div>
        {participants.length > 0 && (
          <div className={style.participantsWrapper}>
            <div className={style.participantList}>
              <InfiniteScroll
                dataLength={results.length - 3}
                height="100%"
                next={() => {
                  dispatch(
                    getGameResults({ quiz_id: quizId, game: { _id: gameId } })
                  );
                }}
                className={style.infiniteScroll}
                hasMore={hasMoreLeaders}
                loader={[1, 2, 3, 4, 5].map((el) => (
                  <div className={style.participantCard} key={el}>
                    <LeaderSkeleton />
                  </div>
                ))}
              >
                {participants.map((participant, index) => (
                  <div className={style.participantCard} key={index}>
                    <div className={style.participantInfo}>
                      <p className={style.participantPlace}>{index + 4}</p>
                      <img
                        src={
                          participant.user_meta.profile_image_url ||
                          defaultUserImage
                        }
                        alt="Participant"
                        className={style.participantImage}
                      />
                      <p className={style.participantName}>
                        {participant.user_meta.username}
                      </p>
                    </div>
                    <p className={style.participantPoints}>
                      {participant.user_score}/{participant.total_score}
                    </p>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        )}
      </div>
    </ModalQuiz>
  );
};

export default GameLeaderBoard;
