import style from "./Preloader.module.scss";

import logoIcon from "../../assets/Logos/blackLogo.svg";

const Preloader = () => {
  return (
    <div className={style.wrapper}>
      <img src={logoIcon} alt="School Hack" />
      <div className={style.spinner}></div>
    </div>
  );
};

export default Preloader;
