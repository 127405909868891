import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../Store/store";
import { MULTIMODAL_API } from "../api/multimodal";
import { SMART_FILES_API } from "../api/requests";
import { MULTIMODAL_HISTORY_LIMIT } from "../constants";

import {
  FetchSessionResponse,
  KnowledgeFileType,
  KnowledgeTabType,
} from "../types";

export interface KnowledgeSliceState {
  isPusherActive: boolean;
  sessionId: string;
  activeTab: KnowledgeTabType;
  fileList: KnowledgeFileType[];
  cachedFileList: KnowledgeFileType[];
  hasMoreFiles: boolean;
  page: number;
  isLoading: boolean;
  requestId: string;

  uploadingFile: KnowledgeFileType | null;
  isLinkUploadModalOpen: boolean;
  uploadPercentage: number;
}

const initialState: KnowledgeSliceState = {
  isPusherActive: false,
  sessionId: "",
  activeTab: "documents",
  fileList: [],
  cachedFileList: [],
  hasMoreFiles: true,
  page: 1,
  isLoading: false,
  requestId: "",

  uploadingFile: null,
  isLinkUploadModalOpen: false,
  uploadPercentage: 0,
};

export const fetchSessionId = createAsyncThunk<
  FetchSessionResponse | undefined
>("knowledge/fetchSessionId", async (_, { rejectWithValue }) => {
  try {
    return await MULTIMODAL_API.postCreateSession(false);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const fetchKnowledgeFiles = createAsyncThunk<
  { fileList: KnowledgeFileType[] } | undefined,
  void,
  { state: RootState }
>(
  "knowledge/fetchKnowledgeFiles",
  async (_, { rejectWithValue, getState, requestId }) => {
    try {
      const activeTab = getState().knowledge.activeTab;

      let fileList: KnowledgeFileType[];

      switch (activeTab) {
        case "documents":
          const documentList = await SMART_FILES_API.getDocuments(
            1,
            MULTIMODAL_HISTORY_LIMIT,
          );
          fileList = documentList;
          break;
        case "images":
          const imageList = await SMART_FILES_API.getImages(
            1,
            MULTIMODAL_HISTORY_LIMIT,
          );
          fileList = imageList;
          break;
        case "video":
          const videoList = await SMART_FILES_API.getMedia(
            1,
            MULTIMODAL_HISTORY_LIMIT,
            false,
          );
          fileList = videoList;
          break;
        case "audio":
          const audioList = await SMART_FILES_API.getMedia(
            1,
            MULTIMODAL_HISTORY_LIMIT,
          );
          fileList = audioList;
          break;
      }

      if (getState().knowledge.requestId !== requestId) {
        return;
      }

      return { fileList };
    } catch (e: unknown) {
      return rejectWithValue(e);
    }
  },
);

export const fetchMoreKnowledgeFiles = createAsyncThunk<
  { fileList: KnowledgeFileType[] } | undefined,
  void,
  { state: RootState }
>(
  "knowledge/fetchMoreKnowledgeFiles",
  async (_, { rejectWithValue, getState }) => {
    try {
      const activeTab = getState().knowledge.activeTab;
      const currentPage = getState().knowledge.page;
      const nextPage = currentPage + 1;

      let fileList: KnowledgeFileType[];

      switch (activeTab) {
        case "documents":
          const documentList = await SMART_FILES_API.getDocuments(
            nextPage,
            MULTIMODAL_HISTORY_LIMIT,
          );
          fileList = documentList;
          break;
        case "images":
          const imageList = await SMART_FILES_API.getImages(
            nextPage,
            MULTIMODAL_HISTORY_LIMIT,
          );
          fileList = imageList;
          break;
        case "video":
          const videoList = await SMART_FILES_API.getMedia(
            nextPage,
            MULTIMODAL_HISTORY_LIMIT,
            false,
          );
          fileList = videoList;
          break;
        case "audio":
          const audioList = await SMART_FILES_API.getMedia(
            nextPage,
            MULTIMODAL_HISTORY_LIMIT,
          );
          fileList = audioList;
          break;
      }

      return { fileList };
    } catch (e: unknown) {
      return rejectWithValue(e);
    }
  },
);

export const knowledgeSlice = createSlice({
  name: "knowledge",
  initialState,
  reducers: {
    setIsPusherActive: (state, action: PayloadAction<boolean>) => {
      state.isPusherActive = action.payload;
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<KnowledgeTabType>) => {
      state.activeTab = action.payload;
    },
    setFileList: (state, action: PayloadAction<KnowledgeFileType[]>) => {
      state.fileList = action.payload;
    },
    setCachedFileList: (state, action: PayloadAction<KnowledgeFileType[]>) => {
      state.cachedFileList = action.payload;
    },
    setHasMoreFiles: (state, action: PayloadAction<boolean>) => {
      state.hasMoreFiles = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUploadFile: (state, action: PayloadAction<KnowledgeFileType | null>) => {
      state.uploadingFile = action.payload;
    },
    setUploadPercentage: (state, action: PayloadAction<number>) => {
      state.uploadPercentage = action.payload;
    },
    setIsLinkUploadModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isLinkUploadModalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSessionId.fulfilled, (state, { payload }) => {
        if (!payload?.session_id) return;
        state.sessionId = payload.session_id;
      })
      .addCase(fetchSessionId.rejected, (_, { error }) => {
        console.error(error);
      });

    builder
      .addCase(fetchKnowledgeFiles.pending, (state, action) => {
        state.isLoading = true;
        state.requestId = action.meta.requestId;
      })
      .addCase(fetchKnowledgeFiles.fulfilled, (state, { payload }) => {
        if (!payload?.fileList) return;

        state.fileList = payload.fileList;
        state.cachedFileList = payload.fileList;
        state.hasMoreFiles =
          payload.fileList.length >= MULTIMODAL_HISTORY_LIMIT;
        state.isLoading = false;
        state.page = 1;
      })
      .addCase(fetchKnowledgeFiles.rejected, (_, { error }) => {
        console.error(error);
      })
      .addCase(fetchMoreKnowledgeFiles.fulfilled, (state, { payload }) => {
        if (!payload?.fileList) return;
        state.fileList = state.fileList.concat(payload.fileList);
        state.cachedFileList = state.cachedFileList.concat(payload.fileList);
        state.hasMoreFiles =
          payload.fileList.length >= MULTIMODAL_HISTORY_LIMIT;
        state.page = state.page + 1;
      })
      .addCase(fetchMoreKnowledgeFiles.rejected, (_, { error }) => {
        console.error(error);
      });
  },
});

export const {
  setIsPusherActive,
  setSessionId,
  setActiveTab,
  setFileList,
  setCachedFileList,
  setHasMoreFiles,
  setPage,
  setIsLoading,
  setUploadFile,
  setUploadPercentage,
  setIsLinkUploadModalOpen,
} = knowledgeSlice.actions;

export default knowledgeSlice.reducer;
