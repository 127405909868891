import axios from "axios";
import { format, isSameDay, isSameYear, isThisWeek, isToday } from "date-fns";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import {
  CurrentSentMessageType,
  GetActiveClassForSidebarLinksParams,
  MessageType,
} from "../types";

export function bytesToMegabytes(bytes: number): string {
  if (!bytes) return "";

  const megabytes = bytes / (1024 * 1024);
  if (megabytes < 0.1) {
    const kilobytes = bytes / 1024;
    return `${kilobytes.toFixed(1)} kb`;
  }
  return `${megabytes.toFixed(1)} mb`;
}

export function truncateString(str: string | undefined, symbols = 30): string {
  if (!str) return "";
  if (str.length > symbols) {
    return str.slice(0, symbols) + "...";
  }
  return str;
}

export function extractFileName(url: string): string {
  const cleanUrl = url.split(/[?#]/)[0].replace(/\/+$/, "");
  const segments = cleanUrl.split("/");
  const lastSegment = segments[segments.length - 1];
  return lastSegment.split(".")[0];
}

export const validateEmail = (email: string): boolean => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export function formatTime(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}

export function formatNumber(num: number | null | undefined): string {
  if (num == null) {
    return "-";
  } else if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1) + "B";
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  } else {
    return num.toString();
  }
}

export function firstCapitalize(string: string | undefined): string {
  if (!string) return "";
  return string[0].toUpperCase();
}

export function firstLetterCapitalize(string: string | undefined): string {
  if (!string) return "";
  const firstChar = string.charAt(0).toUpperCase();
  const restOfString = string.slice(1);
  return firstChar + restOfString;
}

export const onClickCopyToClipboard = (text: string, messageInfo?: string) => {
  if (!navigator.clipboard || !navigator.clipboard.writeText || !text) {
    toast.error("Clipboard API is not supported in this browser.");
    return;
  }

  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast.info(`${messageInfo || "Text"} copied to clipboard`);
    })
    .catch((error) => {
      toast.error(
        "Error copying text to clipboard:",
        error.message || "An error occurred.",
      );
    });
};

export const copyImageToClipboard = async (imageUrl: string) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    const item = new ClipboardItem({ [blob.type]: blob });
    await navigator.clipboard.write([item]);

    toast.info("Image copied to clipboard");
  } catch (error) {
    toast.error("Error copying text to clipboard", error);
  }
};

export const handleDownload = ({
  url,
  fileName,
  type,
}: {
  url: string;
  fileName: string;
  type?: "image";
}) => {
  if (type === "image") {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        const blob = new Blob([res.data], { type: "image/jpeg" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => toast.error("Error downloading file", error));
  } else {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, fileName);
      })
      .catch((error) => toast.error("Error downloading file", error));
  }
};

export function textAreaAdjust(
  element: HTMLTextAreaElement,
  defaultHeight: number,
  maxHeight = 200,
) {
  if (!element) return;

  element.style.height = "0";

  if (!element.value) {
    element.style.height = `${defaultHeight}px`;
    element.style.lineHeight = `${defaultHeight}px`;
    return;
  }

  if (element.scrollHeight > maxHeight) {
    element.style.height = `${maxHeight}px`;
    return;
  }

  element.style.height = `${element.scrollHeight}px`;
}

export const getInitials = (first_name: string, last_name: string) => {
  const firstInitial = firstCapitalize(first_name);
  const lastInitial = firstCapitalize(last_name);
  return `${firstInitial}${lastInitial}`;
};

export function removeAIFromMessage(message: string | undefined): string {
  if (!message) return "";
  const aiPrefix = "@AI";
  const regex = new RegExp(`^${aiPrefix}\\s*`);
  return message.replace(regex, "");
}

export function extractUsername(str: string): string | null {
  const regex = /@(\S*)$/;
  const match = str.match(regex);
  return match ? match[1] : null;
}

export function replaceUsername(input: string, replacement: string): string {
  const regex = /(@\S*)$/;
  return input.replace(regex, `@${replacement} `);
}

export function getDateOfMessageFromArray(
  message: MessageType,
  arr: MessageType[],
): string {
  switch (message.type) {
    case "text":
      const filteredArrayText = arr.filter((el) => el?.body === message.body);
      return filteredArrayText[filteredArrayText.length - 1]?.created_at;
    case "document":
    case "media":
      const filteredArrayDoc = arr.filter(
        (el) =>
          el?.extra_meta?.[message.type]?.id ===
          message.extra_meta?.[message.type]?.id,
      );
      return filteredArrayDoc[filteredArrayDoc.length - 1]?.created_at;
    case "image":
      const filteredArrayImage = arr.filter(
        (el) =>
          el?.extra_meta?.[message.type]?.url ===
          message.extra_meta?.[message.type]?.url,
      );
      return filteredArrayImage[filteredArrayImage.length - 1]?.created_at;
    default:
      return "";
  }
}

export function setSentMessagesByDate(
  arr: CurrentSentMessageType[],
  date: string,
): CurrentSentMessageType[] {
  return arr.filter((el) => el.created_at !== date);
}

export function replaceRealMessage(
  recentMessage: MessageType,
  prevMessage: MessageType,
  date: string,
) {
  return prevMessage.created_at === date ? recentMessage : prevMessage;
}

export function formatDateForMessenger(date: string): string {
  if (!date) return "";

  const currentDate = new Date();
  const targetDate = new Date(date);

  if (isToday(targetDate)) {
    return "Today";
  } else if (isThisWeek(targetDate)) {
    return format(targetDate, "EEEE");
  } else if (isSameYear(currentDate, targetDate)) {
    return format(targetDate, "MMMM d");
  } else {
    return format(targetDate, "dd.MM.yyyy");
  }
}

export function formatDateForSidebar(date: string): string {
  if (!date) return "";

  const currentDate = new Date();
  const targetDate = new Date(date);

  if (isToday(targetDate)) {
    return format(targetDate, "h:mm a");
  } else if (isThisWeek(targetDate)) {
    return format(targetDate, "EEEE");
  } else if (isSameYear(currentDate, targetDate)) {
    return format(targetDate, "MMMM d");
  } else {
    return format(targetDate, "dd.MM.yyyy");
  }
}

export function isNewDay(currentDate: string, nextDate: string): string | null {
  if (!currentDate) return null;
  if (!nextDate || !isSameDay(new Date(nextDate), new Date(currentDate))) {
    return formatDateForMessenger(currentDate);
  }
  return null;
}

export function getActiveClassForSidebarLinks({
  routeBasePath,
  pathname,
  search,
  styleActive,
}: GetActiveClassForSidebarLinksParams): string | undefined {
  if (pathname === "/smart-media" && search === "?action=ai-recorder") {
    return routeBasePath === "/smart-media?action=ai-recorder"
      ? styleActive
      : undefined;
  }

  if (
    pathname === routeBasePath ||
    pathname.split("/")[1] === routeBasePath.split("/")[1]
  ) {
    return styleActive;
  }
}

export const addBlockIdToHistoryItem = (history) => {
  return history.map((item) => ({
    ...item,
    blockId: uuidv4(),
    isBotFinished: true,
  }));
};

export const openPdfInNewTab = (pdfUrl: string) => {
  window.open(pdfUrl, "_blank");
};

export const handleApiError = (error) => {
  if (error.response) {
    const errorMessage = error.response.data.message || "An error occurred";
    toast.error(errorMessage);
  } else if (error.request) {
    toast.error("No response received from the server");
  } else {
    toast.error("An error occurred while processing the request");
  }
};

export const getRecentMessageHeading = (recentMessage: MessageType): string => {
  if (!recentMessage) return "";

  switch (recentMessage.type) {
    case "media":
      return `${recentMessage.user_meta?.first_name} sent a media file "${recentMessage.extra_meta?.media.name}"`;
    case "document":
      return `${recentMessage.user_meta?.first_name} sent a document "${recentMessage.extra_meta?.document.name}"`;
    case "image":
      return `${recentMessage.user_meta?.first_name} sent an image`;
    case "ai_chat":
      return "Andy Bot sent a message";
    default:
      return `${recentMessage.user_meta?.first_name} sent a message`;
  }
};
