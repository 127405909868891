import { useState } from "react";

import { useAppSelector } from "../../../hooks/redux-hooks";
import Notifications from "../Notifications/Notifications";

import style from "./NotificationsBell.module.scss";

import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { BellIcon } from "../../icons";

const NotificationsBell = () => {
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);

  const newNotifications = useAppSelector((state) => state.user.newMessages);
  const notifications = useAppSelector((state) => state.user.notifications);

  const displayNotificationsCount = (notifications: number) => {
    if (notifications > 5) return "5+";
    else if (notifications > 0) return notifications;
    else return "0";
  };

  useOutsideClick({
    containerId: "notificationModal",
    isOpened: isNotificationModalOpen,
    close: () => setIsNotificationModalOpen(false),
  });

  return (
    <div id="notificationModal" className={style.headerBtnGroup}>
      <button onClick={() => setIsNotificationModalOpen((prev) => !prev)}>
        <BellIcon />
      </button>

      <p
        className={
          newNotifications ? style.notificationDot : style.noNotificationDot
        }
      >
        {displayNotificationsCount(newNotifications)}
      </p>

      {isNotificationModalOpen ? (
        <Notifications
          setNotification={setIsNotificationModalOpen}
          notifications={notifications}
        />
      ) : null}
    </div>
  );
};

export default NotificationsBell;
