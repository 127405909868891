import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  FC,
  ChangeEventHandler,
} from "react";
import { useNavigate } from "react-router";

import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import useMediaQuery from "../../hooks/useMediaQuery";
import {
  fetchCompanyLink,
  fetchCompanyUnlink,
} from "../../Slice/companyLinkSlice";
import { setIsModalCryptoOpened } from "../../Slice/cryptoSlice";
import {
  fetchUserInfo,
  getUnreadNotifications,
  initNotifications,
} from "../../Slice/userSlice";
import { COMPANY_LINK } from "../../constants";
import { COOKIE_TOKEN_NAME, deleteCookie } from "../../helpers/cookies";
import {
  removeCompanyId,
  removePrimary,
  removeSecondary,
} from "../../helpers/localStorage";
import CompanyLinkingModal from "../CompanyLinking";
import CreditsInfo from "../CreditsInfo/CreditsInfo";
import ThemeButton from "../ThemeButton/ThemeButton";
import InstituteBlock from "./InstituteBlock/InstituteBlock";
import NavbarProfile from "./NavbarProfile/NavbarProfile";
import NotificationsBell from "./NotificationsBell/NotificationsBell";
import ToggleSidebarBtn from "./ToggleSidebarBtn/ToggleSidebarBtn";

import style from "./Navbar.module.scss";

import metamaskIcon from "../../assets/New/Images/Metamask.png";

const savedCompanyLink = localStorage.getItem(COMPANY_LINK);

interface NavbarProps {
  sidebarIsOpen: boolean;
  setSidebarIsOpen: Dispatch<SetStateAction<boolean>>;
  modalOpened: boolean;
  setModalOpened: Dispatch<SetStateAction<boolean>>;
}

const Navbar: FC<NavbarProps> = ({
  sidebarIsOpen,
  setSidebarIsOpen,
  setModalOpened,
  modalOpened,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [companyCode, setCompanyCode] = useState("");

  const isShowThemeButton = useMediaQuery("(min-width: 833px)");

  const info = useAppSelector((state) => state.user.info);
  const { isLinked, loading, error } = useAppSelector(
    (state) => state.isLinked?.companyLink,
  );

  const { name = "", logo_url = "", code = "" } = info?.linked_company_meta;

  useEffect(() => {
    void getNotificationsLength();

    async function getNotificationsLength() {
      const length = await getUnreadNotifications();
      if (length > 0) dispatch(initNotifications(length));
    }
  }, []);

  const handleLinkClick = () => {
    if (!companyCode.trim()) return;

    setModalOpened(true);
    dispatch(fetchCompanyLink(companyCode.trim()));
  };

  const handleRelinkClick = () => {
    dispatch(fetchCompanyLink(savedCompanyLink));
    setModalOpened(true);
    dispatch(fetchUserInfo({ navigate }));
  };

  const handleUnlinkClick = async () => {
    await dispatch(fetchCompanyUnlink());
    setCompanyCode("");
    removePrimary();
    removeSecondary();
    removeCompanyId();
    window.location.reload();
  };

  const handleCompanyCodeChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    setCompanyCode(event.target.value);
  };

  const handleLogout = () => {
    deleteCookie(COOKIE_TOKEN_NAME);
    localStorage.removeItem("company-link");
    localStorage.removeItem("companyId");
    localStorage.removeItem("companySecondaryColor");
    localStorage.removeItem("companyPrimaryColor");
    window.localStorage.setItem("theme", "dark");
    window.location.reload();
  };

  return (
    <section
      className={style.navbarContainer}
      style={{
        backgroundColor: `${info?.linked_company_meta?.theme?.primary_colour}`,
      }}
    >
      <div className={style.leftSide}>
        <InstituteBlock
          handleCompanyCodeChange={handleCompanyCodeChange}
          handleLinkClick={handleLinkClick}
          companyCode={companyCode}
        />

        <ToggleSidebarBtn
          sidebarIsOpen={sidebarIsOpen}
          setSidebarIsOpen={setSidebarIsOpen}
        />
      </div>

      <div className={style.rightSide}>
        <button
          className={style.metamaskBtn}
          onClick={() => {
            navigate("/");
            dispatch(setIsModalCryptoOpened(true));
          }}
        >
          <img src={metamaskIcon} alt="Metamask" />
        </button>

        <NotificationsBell />

        <div className={style.creditsWrapper}>
          <CreditsInfo />
        </div>

        {!info.linked_company_id && isShowThemeButton && <ThemeButton />}

        <NavbarProfile
          handleUnlinkClick={handleUnlinkClick}
          handleLogout={handleLogout}
          handleRelinkClick={handleRelinkClick}
        />
      </div>

      {modalOpened && !error && (
        <CompanyLinkingModal
          name={name}
          logo_url={logo_url}
          code={code}
          loading={loading}
          error={error}
          isLinked={isLinked}
          setModalOpened={setModalOpened}
        />
      )}
    </section>
  );
};

export default Navbar;
