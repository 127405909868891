import { FC } from "react";

import SkeletonWrapper from "../../SkeletonComponent";

import style from "./NotificationSkeleton.module.scss";

const NotificationSkeleton: FC = () => (
  <div className={style.wrapper}>
    <SkeletonWrapper width="100%" height="56px">
      <rect x="70" y="10" rx="2" ry="2" width="100" height="10" />
      <rect x="70" y="30" rx="3" ry="3" width="60" height="10" />
      <circle cx="33" cy="30" r="15" />
    </SkeletonWrapper>
  </div>
);

export default NotificationSkeleton;
