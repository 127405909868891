import { useEffect } from "react";
import { useLocation } from "react-router";
import { useAppDispatch } from "../hooks/redux-hooks";
import { addRouteToHistoryStack } from "../Slice/userSlice";

const History = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    const { pathname, search } = location;
    dispatch(addRouteToHistoryStack(`${pathname}${search}`));
  }, [location.pathname]);

  return null;
};

export default History;
