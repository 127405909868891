import { FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as yup from "yup";

import Modal from "../../../../../Components/Modal";
import classNames from "../../../../../hooks/classNames";
import Toggle from "../../../../../Components/Toggle";
import { GAME_API } from "../../../../../api/requests";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { setCurrentGame } from "../../../../../Slice/gameSlice";
import { IFormFields } from "../../../../../types";

import style from "./ModalGameSettings.module.scss";

import schoolHackTokenIcon from "../../../../../assets/New/Icons/schoolHackTokenIcon.svg";

const schema = yup.object({
  name: yup.string().required("This field is required").trim(),
  description: yup.string().max(500, "Must be less than 500").trim(),
  question_duration: yup
    .number()
    .typeError("This field is required")
    .required("This field is required")
    .min(3, "Must be at least 3")
    .max(30, "Must be less than 30"),
});

interface ModalGameSettingsProps {
  closeModal: () => void;
  isGameInfoOpened?: boolean;
}

const ModalGameSettings: FC<ModalGameSettingsProps> = ({
  closeModal,
  isGameInfoOpened,
}) => {
  const dispatch = useAppDispatch();

  const currentGame = useAppSelector((state) => state.game.currentGame);

  const methods = useForm<IFormFields>({
    resolver: yupResolver<any>(schema),
    defaultValues: {
      name: currentGame.name,
      description: currentGame.description,
      winner_takes_all: currentGame.winner_takes_all,
      allow_owner_participation: currentGame.settings.allow_owner_participation,
      question_duration: currentGame.settings.question_duration,
      max_questions: currentGame.settings.max_questions,
      per_game_cost: currentGame.per_game_cost,
    },
  });

  const { handleSubmit, formState, register } = methods;
  const { errors } = formState;

  const onSubmit = async ({
    name,
    description,
    winner_takes_all,
    question_duration,
    allow_owner_participation,
  }: IFormFields) => {
    if (isGameInfoOpened) {
      closeModal();
      return;
    }

    const res = await GAME_API.putGameSettings(currentGame._id, {
      name,
      description,
      winner_takes_all,
      per_game_cost: currentGame.per_game_cost,
      settings: {
        question_duration,
        allow_owner_participation,
      },
    });
    if (res) {
      dispatch(setCurrentGame(res.game));
      toast.success("Game settings updated");
    }

    closeModal();
  };

  return (
    <Modal closeModal={closeModal} styles={style.modal}>
      <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
        <p className={style.heading}>
          Game room {isGameInfoOpened ? "info" : "settings"}
        </p>
        <div className={style.labelContainer}>
          <label className={style.label}>
            Room Name
            <input
              type="text"
              className={classNames(
                style.formInput,
                isGameInfoOpened && style.disabled
              )}
              {...register("name")}
              disabled={isGameInfoOpened}
            />
            {errors.name && (
              <p className={style.errorMessage}>{errors.name.message}</p>
            )}
          </label>
          <label className={style.label}>
            Enter Maximum number of questions
            <input
              type="number"
              className={classNames(style.formInput, style.disabled)}
              disabled
              {...register("max_questions")}
            />
            {errors.max_questions && (
              <p className={style.errorMessage}>
                {errors.max_questions.message}
              </p>
            )}
          </label>
        </div>
        <label className={style.switch}>
          Winner takes all
          <Toggle
            className={classNames(
              (!currentGame.per_game_cost || isGameInfoOpened) &&
                style.disabled,
              style.toggleBg
            )}
            register={register("winner_takes_all")}
            disabled={!currentGame.per_game_cost || isGameInfoOpened}
          />
        </label>
        {!isGameInfoOpened && (
          <label className={style.switch}>
            Allow owner to participate
            <Toggle
              className={classNames(
                isGameInfoOpened ? style.disabled : undefined,
                style.toggleBg
              )}
              register={register("allow_owner_participation")}
              disabled={isGameInfoOpened}
            />
          </label>
        )}
        <label className={style.label}>
          Description
          <div className={style.formInputContainer}>
            <textarea
              className={classNames(
                style.formInput,
                style.textarea,
                isGameInfoOpened && style.disabled
              )}
              disabled={isGameInfoOpened}
              {...register("description")}
            />
          </div>
        </label>
        {errors.description && (
          <p className={classNames(style.errorMessage, style.descriptionErr)}>
            {errors.description.message}
          </p>
        )}
        <div className={style.labelContainer}>
          <label className={style.label}>
            Game Fees
            <div className={style.formInputContainer}>
              <input
                type="number"
                className={classNames(
                  style.formInput,
                  style.withHelperText,
                  style.disabled
                )}
                disabled
                {...register("per_game_cost")}
              />
              <div
                className={classNames(style.inputHelperText, style.disabled)}
              >
                <p>Credits</p>
                <img src={schoolHackTokenIcon} alt="Coin" />
              </div>
            </div>
            {errors.per_game_cost && (
              <p className={style.errorMessage}>
                {errors.per_game_cost.message}
              </p>
            )}
          </label>
          <label className={style.label}>
            {isGameInfoOpened ? "" : "Enter"} Question Duration
            <div className={style.formInputContainer}>
              <input
                type="number"
                className={classNames(
                  style.formInput,
                  style.withHelperText,
                  isGameInfoOpened && style.disabled
                )}
                disabled={isGameInfoOpened}
                {...register("question_duration")}
              />
              <p className={style.inputHelperText}>Seconds</p>
            </div>
            {errors.question_duration && (
              <p className={style.errorMessage}>
                {errors.question_duration.message}
              </p>
            )}
          </label>
        </div>
        {!isGameInfoOpened && (
          <button className={style.submitBtn} type="submit">
            Update
          </button>
        )}
      </form>
    </Modal>
  );
};

export default ModalGameSettings;
