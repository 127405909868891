import { Dispatch, FC, SetStateAction } from "react";
import { useNavigate } from "react-router";

import { useAppSelector } from "../../../hooks/redux-hooks";
import Avatar from "../../Avatar/Avatar";

import { UserMetaType } from "../../../types";

import style from "./UserProfile.module.scss";

interface UserProfileProps {
  handleOnProfileImageClick: () => void;
  setSidebarIsOpen: Dispatch<SetStateAction<boolean>>;
}

const UserProfile: FC<UserProfileProps> = ({
  handleOnProfileImageClick,
  setSidebarIsOpen,
}) => {
  const navigate = useNavigate();
  const info = useAppSelector((state) => state.user.info);

  return (
    <div
      className={style.profileContainer}
      onClick={() => {
        navigate("/profile");
        setSidebarIsOpen(false);
      }}
    >
      <Avatar
        userMeta={info as unknown as UserMetaType}
        handleClick={handleOnProfileImageClick}
        openModal={false}
        className={style.profileImageWrapper}
      />

      <div className={style.profileDetails}>
        <p className={style.profileName}>
          {info?.first_name || "User"} {info?.last_name}
        </p>
        <p className={style.profileEmail}>{info?.email}</p>
      </div>
    </div>
  );
};

export default UserProfile;
