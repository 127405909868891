import { FC } from "react";
import { Navigate, Outlet } from "react-router";

import { COOKIE_TOKEN_NAME, getCookie } from "../../helpers/cookies";

import style from "./AuthLayout.module.scss";

const token = getCookie(COOKIE_TOKEN_NAME);

const AuthLayout: FC = () => {
  if (token) {
    return <Navigate to="/" replace />;
  }

  return (
    <main className={style.container}>
      <Outlet />
    </main>
  );
};
export default AuthLayout;
