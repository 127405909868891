import { createSlice } from "@reduxjs/toolkit";

export interface CryptoSlice {
  isModalCryptoOpened: boolean;
  network: string;
  token: string;
  signature: string;
  amount: number;
  account: string;
  transactionHash: string;
}

const initialState: CryptoSlice = {
  isModalCryptoOpened: false,
  network: "ethereum",
  token: "",
  signature: "",
  amount: 1,
  account: "",
  transactionHash: "",
};

export const cryptoSlice = createSlice({
  name: "crypto",
  initialState,
  reducers: {
    setIsModalCryptoOpened: (state, action) => {
      state.isModalCryptoOpened = action.payload;
    },
    setNetwork: (state, action) => {
      state.network = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setAmount: (state, action) => {
      state.amount = action.payload;
    },
    setAccount: (state, action) => {
      state.account = action.payload;
    },
  },
  extraReducers: () => {},
});

export const {
  setIsModalCryptoOpened,
  setNetwork,
  setToken,
  setAmount,
  setAccount,
} = cryptoSlice.actions;

export default cryptoSlice.reducer;
