import React, {
  MouseEventHandler,
  WheelEventHandler,
  useState,
  useEffect,
} from "react";
import { createPortal } from "react-dom";
import { MdRotate90DegreesCcw } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

import classNames from "../../hooks/classNames";
import useEscapeKey from "../../hooks/useEscape";
import { handleDownload } from "../../helpers/string";
import AsyncImage from "../AsyncImage/AsyncImage";

import style from "./ZoomedPictureModal.module.scss";

import downloadIcon from "../../assets/Icons/downloadIcon.svg";

interface ZoomedPictureProps {
  closeModal: () => void;
  image: string;
  isZoomedPictureOpen: boolean;
  imageWrapperClassName?: string;
  backdropClassName?: string;
}

const ZoomedPictureModal = ({
  closeModal,
  image,
  imageWrapperClassName,
  backdropClassName,
  isZoomedPictureOpen,
}: ZoomedPictureProps) => {
  useEscapeKey(closeModal);

  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);

  const handleScroll: WheelEventHandler = (e) => {
    const delta = e.deltaY;
    const scaleFactor = 0.1;

    if (delta > 0) {
      if (scale > 0.5) {
        setScale(scale - scaleFactor);
      }
    } else {
      if (scale < 2) {
        setScale(scale + scaleFactor);
      }
    }
  };

  const handleOutsideClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target === e.currentTarget) closeModal();
  };

  const handleRotateImage = () => {
    setRotation((prevRotation) => (prevRotation - 90) % 360);
  };

  return createPortal(
    <div
      className={classNames(
        style.backdrop,
        isZoomedPictureOpen && style.isOpen,
        backdropClassName,
      )}
    >
      <button onClick={closeModal} className={style.closeBtn}>
        <RxCross2 className={style.crossIcon} />
      </button>

      <div
        className={classNames(style.imageWrapper, imageWrapperClassName)}
        onClick={handleOutsideClick}
        onWheel={handleScroll}
      >
        <AsyncImage
          shouldLoad={isZoomedPictureOpen}
          src={image}
          alt="Zoomed picture"
          loading="lazy"
          style={{
            transform: `scale(${scale}) rotate(${rotation}deg)`,
            maxWidth: "100%",
            maxHeight: "100%",
            overflow: "hidden",
          }}
        />
      </div>

      <button onClick={handleRotateImage} className={style.rotateBtn}>
        <MdRotate90DegreesCcw size={35} />
      </button>

      <button
        onClick={() =>
          handleDownload({ url: image, fileName: "SH_IMG", type: "image" })
        }
        className={style.downloadBtn}
      >
        <img src={downloadIcon} alt="Download" />
      </button>
    </div>,
    document.getElementById("root") as HTMLElement,
  );
};

export default ZoomedPictureModal;
