import ContentLoader from "react-content-loader";

import { selectTheme } from "../../../../Slice/userSlice";
import { useAppSelector } from "../../../../hooks/redux-hooks";

const SettingsGroupImageSkeleton = () => {
  const { isLightTheme, isLinked } = useAppSelector(selectTheme);

  return (
    <div>
      <ContentLoader
        speed={2}
        width={320}
        height={200}
        viewBox="0 0 320 200"
        backgroundColor={isLightTheme || isLinked ? "#d6d6d6" : "#383838"}
        foregroundColor={isLightTheme || isLinked ? "#bababa" : "#666666"}
      >
        <circle cx="42" cy="50" r="42" />
      </ContentLoader>
    </div>
  );
};

export default SettingsGroupImageSkeleton;
