import React, { useState } from "react";
import { createPortal } from "react-dom";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import CreditsInfo from "../../../../../Components/CreditsInfo/CreditsInfo";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import useEscapeKey from "../../../../../hooks/useEscape";
import { setInvitedGameWithModal } from "../../../../../Slice/gameSlice";
import { GAME_API } from "../../../../../api/requests";
import ModalGameSettings from "../ModalGameSettings/ModalGameSettings";

import style from "./ModalQuiz.module.scss";

import LeaveIcon from "../../../../../assets/Icons/LeaveIcon";
import closeIcon from "../../../../../assets/Icons/close.svg";
import settingsIcon from "../../../../../assets/Icons/settings.svg";

interface ModalQuizProps {
  children: React.ReactNode;
  closeModal: () => void;
  notShowButtons?: boolean;
  isHistoryPage?: boolean;
}

const ModalQuiz: React.FC<ModalQuizProps> = ({
  children,
  closeModal,
  notShowButtons,
  isHistoryPage,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEscapeKey(closeModal);

  const [isModalGameSettingsOpened, setIsModalGameSettingsOpened] =
    useState(false);

  const info = useAppSelector((state) => state.user.info);
  const currentGame = useAppSelector((state) => state.game.currentGame);

  const isAdmin = info?.user_id === currentGame?.user_id;

  const openModal = () => setIsModalGameSettingsOpened(true);

  const onClickLeaveGame = async () => {
    try {
      if (isAdmin) {
        await GAME_API.deleteGame(currentGame?._id);
      } else {
        await GAME_API.exitGame(currentGame?._id);
      }
      dispatch(setInvitedGameWithModal(null));
      navigate("/game/multi");
      toast.success("Game has been removed");
    } catch (error) {
      toast.error(error?.response?.data?.message || "ERROR");
    }
  };

  return createPortal(
    <div className={style.overlay}>
      {!isHistoryPage && (
        <div className={style.buttonsWrapper}>
          {!notShowButtons && (
            <button className={style.leaveGame} onClick={onClickLeaveGame}>
              <div className={style.leaveIcon}>
                <LeaveIcon
                  col={"#ff4545"}
                  height={30}
                  width={30}
                  margin="0 10px 0 0"
                />
              </div>
              {isAdmin ? "Delete" : "Leave"} Game
            </button>
          )}

          <CreditsInfo />

          {!notShowButtons && (
            <button className={style.infoIconBtn} onClick={openModal}>
              {isAdmin ? (
                <img
                  src={settingsIcon}
                  alt="Settings"
                  className={style.settingsIcon}
                />
              ) : (
                <AiOutlineInfoCircle className={style.infoIcon} />
              )}
            </button>
          )}

          <button className={style.closeBtn} onClick={closeModal}>
            <img src={closeIcon} alt="Close" />
          </button>
        </div>
      )}

      <div className={style.contentWrapper}>{children}</div>

      {isModalGameSettingsOpened && (
        <ModalGameSettings
          closeModal={() => setIsModalGameSettingsOpened(false)}
          isGameInfoOpened={!isAdmin}
        />
      )}
    </div>,
    document.body.querySelector("#rootContainer")
  );
};

export default ModalQuiz;
