import React, { ComponentProps, useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

import style from "./InputField.module.scss";

interface InputFieldProps extends ComponentProps<"input"> {
  type: string;
  register?: UseFormRegisterReturn;
}

const InputField: React.FC<InputFieldProps> = ({
  type,
  register,
  ...props
}) => {
  const [inputType, setInputType] = useState<string>(type);

  const handleType = () => {
    if (inputType === "password") {
      setInputType("text");
    }
    if (inputType === "text") {
      setInputType("password");
    }
  };

  return (
    <div className={style.componentContainer}>
      <div className={style.inputFieldContainer}>
        <span className={style.inputClass}>
          <input
            autoComplete="off"
            className={style.input}
            type={inputType}
            {...register}
            {...props}
          />
        </span>
        {type === "password" &&
          (inputType === "password" ? (
            <AiFillEyeInvisible className={style.eye} onClick={handleType} />
          ) : (
            <AiFillEye className={style.eye} onClick={handleType} />
          ))}
      </div>
    </div>
  );
};

export default InputField;
