import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";

import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import Modal from "../../../Components/Modal";
import classNames from "../../../hooks/classNames";
import {
  setCurrentGame,
  setInvitedGameWithModal,
} from "../../../Slice/gameSlice";

import style from "./GameInviteSecondaryModal.module.scss";

import profileLogo from "../../../assets/Icons/profileLogo.jpg";
import waitingImage from "../../../assets/json/countdown-circles.json";

interface GameInviteSecondaryModalProps {
  closeModal?: () => void;
}

const GameInviteSecondaryModal: React.FC<GameInviteSecondaryModalProps> = ({
  closeModal,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const invitedGame = useAppSelector((state) => state.game.invitedGame);
  const currentQuestion = useAppSelector((state) => state.game.currentQuestion);
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    if (!currentQuestion) return;

    if (accepted) {
      handleJoinClick();
    } else {
      closeModalFunction();
    }
  }, [currentQuestion]);

  const handleJoinClick = () => {
    dispatch(setCurrentGame(invitedGame));
    navigate(`/game/multi/quiz?gameId=${invitedGame?._id}`);
    closeModal();
  };

  const closeModalFunction = () => {
    dispatch(setInvitedGameWithModal(null));
    closeModal();
  };

  const acceptButtonLabel = accepted ? "Accepted" : "Accept";

  return (
    <Modal closeModal={closeModalFunction} styles={style.modal}>
      <div className={style.container}>
        <div className={style.mainProfile}>
          <img
            src={invitedGame?.user_meta?.profile_image_url || profileLogo}
            alt="Group"
            className={style.profile}
          />
        </div>
        <div className={style.detailsContainer}>
          <p
            className={style.description}
          >{`${invitedGame?.user_meta.first_name}'s Game`}</p>
          <h3 className={style.groupName}>{invitedGame?.name}</h3>
        </div>
        <p className={style.startingText}>Starting Game in</p>
        <Lottie
          className={style.waitingImage}
          animationData={waitingImage}
          loop={false}
          reversed
        />

        <div className={style.buttonContainer}>
          <button
            disabled={accepted}
            className={classNames(style.button, accepted && style.accepted)}
            onClick={() => {
              setAccepted(true);
            }}
          >
            {acceptButtonLabel}
          </button>
          <button
            className={classNames(style.button, style.rejectBtn)}
            onClick={closeModalFunction}
          >
            Reject
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default GameInviteSecondaryModal;
