import { FC } from "react";

import classNames from "../../hooks/classNames";
import { handleImageError } from "../../helpers/handleImageError";
import ZoomedPicture from "../ZoomedPicture/ZoomedPicture";

import { UserMetaType } from "../../types";

import style from "./Avatar.module.scss";

import defaultAvatarIcon from "../../assets/Icons/profileLogo.jpg";
import askAndyIcon from "../../assets/New/Images/askAndy.png";

interface AvatarProps {
  userMeta: UserMetaType;
  className?: string;
  handleClick?: () => void;
  openModal?: boolean;
  isBot?: boolean;
}

const Avatar: FC<AvatarProps> = ({
  userMeta,
  className,
  openModal = true,
  isBot,
  handleClick,
}) => {
  return (
    <div
      className={classNames(style.avatarWrapper, className)}
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
      }}
    >
      {openModal ? (
        <ZoomedPicture
          src={
            isBot
              ? askAndyIcon
              : userMeta?.profile_image_url || defaultAvatarIcon
          }
          alt="User Avatar"
          className={style.memberImage}
          onError={handleImageError}
        />
      ) : (
        <img
          src={
            isBot
              ? askAndyIcon
              : userMeta?.profile_image_url || defaultAvatarIcon
          }
          alt="User Avatar"
          className={style.memberImage}
          onError={handleImageError}
        />
      )}
    </div>
  );
};

export default Avatar;
