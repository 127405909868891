import { useEffect } from "react";

interface UseOutsideClickParams {
  containerId: string;
  isOpened: boolean;
  close: () => void;
}

export const useOutsideClick = ({
  containerId,
  isOpened,
  close,
}: UseOutsideClickParams) => {
  useEffect(() => {
    if (!isOpened) return;

    window.addEventListener("mousedown", handleOutsideSidebarClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideSidebarClick);
    };
  }, [isOpened]);

  const handleOutsideSidebarClick = (e: MouseEvent) => {
    if (!(e.target as HTMLElement).closest(`#${containerId}`)) {
      close();
    }
  };
};
