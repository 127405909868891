import { FC, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import classNames from "../../../hooks/classNames";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { COMPANY_LINK } from "../../../constants";
import Avatar from "../../Avatar/Avatar";

import { UserMetaType } from "../../../types";

import style from "./NavbarProfile.module.scss";

interface NavbarProfileProps {
  handleUnlinkClick: () => void;
  handleLogout: () => void;
  handleRelinkClick: () => void;
}

const NavbarProfile: FC<NavbarProfileProps> = ({
  handleUnlinkClick,
  handleLogout,
  handleRelinkClick,
}) => {
  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);

  const info = useAppSelector((state) => state.user.info);
  const savedCompanyLink = localStorage.getItem(COMPANY_LINK);

  return (
    <div
      className={classNames(
        style.profile,
        info.linked_company_id && style.linked,
      )}
    >
      <div
        className={style.avatarDropdown}
        onMouseLeave={() => setShowDropdown(false)}
      >
        <button
          type="button"
          onClick={() => navigate("/profile")}
          onMouseEnter={() => setShowDropdown(true)}
        >
          <Avatar
            openModal={false}
            userMeta={info as unknown as UserMetaType}
            className={style.profileImg}
          />
        </button>
        <Dropdown show={showDropdown}>
          <Dropdown.Menu className={style.dropdownMenu}>
            {info?.linked_company_id && (
              <Dropdown.Item
                onClick={handleUnlinkClick}
                className={style.dropdownItem}
              >
                Unlink
              </Dropdown.Item>
            )}
            {!info?.linked_company_id && savedCompanyLink && (
              <Dropdown.Item
                onClick={handleRelinkClick}
                className={style.dropdownItem}
              >
                Relink
              </Dropdown.Item>
            )}
            <Dropdown.Item as="div" className={style.dropdownItem}>
              <Link to="/profile">Settings</Link>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={handleLogout}
              className={style.dropdownItem}
            >
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default NavbarProfile;
