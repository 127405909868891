import { Suspense, useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

import Navbar from "../../Components/Navbar";
import Preloader from "../../Components/Preloader/Preloader";
import Sidebar from "../../Components/Sidebar";
import classNames from "../../hooks/classNames";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import GameInviteSecondaryModal from "../../Pages/Game/GameInviteModals/GameInviteSecondaryModal";
import { setIsModalGameStartedOpen } from "../../Slice/gameSlice";
import {
  fetchUserInfo,
  getLinkedFromStorage,
  selectTheme,
  setAppTheme,
} from "../../Slice/userSlice";
import { COOKIE_TOKEN_NAME, getCookie } from "../../helpers/cookies";

import style from "./MainLayout.module.scss";

const theme = window.localStorage.getItem("theme");

const MainLayout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);

  const isChatBotModalOpened = useAppSelector(
    (state) => state.home.isChatBotModalOpened,
  );

  const userId = useAppSelector((state) => state.user.info?.user_id);
  const { isLinked, isLightTheme } = useAppSelector(selectTheme);
  const isModalGameStartedOpen = useAppSelector(
    (state) => state.game.isModalGameStartedOpen,
  );

  const token = getCookie(COOKIE_TOKEN_NAME);

  useEffect(() => {
    if (token) dispatch(fetchUserInfo(navigate));

    if (theme === "dark" || theme === "light") {
      dispatch(setAppTheme(theme));
      return;
    }

    dispatch(getLinkedFromStorage());
  }, []);

  const closeModal = () => dispatch(setIsModalGameStartedOpen(false));

  if (!token) {
    return <Navigate to="/auth/login" replace />;
  }

  return (
    <>
      <div
        id="rootContainer"
        className={classNames(
          !isChatBotModalOpened && style.rootContainer,
          (isLinked && "linked") || (isLightTheme && "light") || "dark",
        )}
      >
        {!isChatBotModalOpened && (
          <Sidebar
            sidebarIsOpen={sidebarIsOpen}
            setSidebarIsOpen={setSidebarIsOpen}
            setModalOpened={setModalOpened}
          />
        )}
        <div className={style.mainContent}>
          <Navbar
            modalOpened={modalOpened}
            setModalOpened={setModalOpened}
            sidebarIsOpen={sidebarIsOpen}
            setSidebarIsOpen={setSidebarIsOpen}
          />
          <main className={classNames(userId && style.authorized)}>
            <Suspense fallback={<Preloader />}>
              <Outlet />
            </Suspense>
          </main>
        </div>
      </div>
      {isModalGameStartedOpen && (
        <GameInviteSecondaryModal closeModal={closeModal} />
      )}
    </>
  );
};

export default MainLayout;
