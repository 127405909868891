export const getCompanyId = () => {
  return localStorage.getItem("companyId") || "";
};

export const setCompanyId = (linked_company_id: string) => {
  localStorage.setItem("companyId", linked_company_id);
};

export const removeCompanyId = () => {
  localStorage.removeItem("companyId");
};

export const getPrimary = () => {
  return localStorage.getItem("companyPrimaryColor") || "";
};

export const setPrimary = (primary_colour: string) => {
  localStorage.setItem("companyPrimaryColor", primary_colour);
};

export const removePrimary = () => {
  localStorage.removeItem("companyPrimaryColor");
};

export const getSecondary = () => {
  return localStorage.getItem("companySecondaryColor") || "";
};

export const setSecondary = (secondary_color: string) => {
  localStorage.setItem("companySecondaryColor", secondary_color);
};

export const removeSecondary = () => {
  localStorage.removeItem("companySecondaryColor");
};
