import { FC } from "react";

import { selectTheme } from "../../Slice/userSlice";
import { useAppSelector } from "../../hooks/redux-hooks";
import classNames from "../../hooks/classNames";

import style from "./Spinner.module.scss";

import SpinnerAnimation from "react-bootstrap/Spinner";

interface SpinnerProps {
  className?: string;
  variant?: "dark" | "light";
}

const Spinner: FC<SpinnerProps> = ({ className, variant }) => {
  const { isLinked, isLightTheme } = useAppSelector(selectTheme);

  return (
    <div className={classNames(style.container, className)}>
      <SpinnerAnimation
        animation="border"
        role="status"
        size="sm"
        variant={
          variant ? variant : isLinked || isLightTheme ? "dark" : "light"
        }
      />
    </div>
  );
};

export default Spinner;
