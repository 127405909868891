import { useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import { CRYPTO_API } from "../../api/crypto";
import {
  COOKIE_TOKEN_NAME,
  deleteCookie,
  getCookie,
  setCookie,
} from "../../helpers/cookies";
import {
  setAmount,
  setIsModalCryptoOpened,
  setNetwork,
  setToken,
} from "../../Slice/cryptoSlice";
import { useAppDispatch } from "../../hooks/redux-hooks";

import style from "./CryptoHandoff.module.scss";

const CryptoHandoff = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const session_grant_key = searchParams.get("session_grant_key");
  const network = searchParams.get("network");
  const token = searchParams.get("token");
  const amount = searchParams.get("amount");

  if (!session_grant_key) {
    return <Navigate to="/" />;
  }

  useEffect(() => {
    async function getCode() {
      const data = await CRYPTO_API.exchangeGrantKey(session_grant_key);
      if (data?.access_token) {
        const previousCookie = getCookie(COOKIE_TOKEN_NAME);
        if (previousCookie) {
          deleteCookie(COOKIE_TOKEN_NAME);
          localStorage.removeItem("company-link");
          localStorage.removeItem("companyId");
          localStorage.removeItem("companySecondaryColor");
          localStorage.removeItem("companyPrimaryColor");
          window.localStorage.setItem("theme", "dark");
        }
        setCookie(COOKIE_TOKEN_NAME, data.access_token);
        dispatch(setNetwork(network));
        dispatch(setToken(token));
        dispatch(setAmount(amount));
        dispatch(setIsModalCryptoOpened(true));
      }
      navigate("/");
    }
    if (session_grant_key) {
      getCode();
    }
  }, []);

  return (
    <h1 className={style.text}>
      Logging you into please wait you will be redirected shortly
    </h1>
  );
};

export default CryptoHandoff;
