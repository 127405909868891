export const BellIcon = () => {
  return (
    <svg
      width="32"
      height="34"
      viewBox="0 0 32 34"
      fill="transparent"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4596_4277)">
        <path
          d="M32 27.1115C30.6875 27.1115 29.9325 27.1569 29.1833 27.1001C26.5176 26.8957 24.4972 27.0888 22.9167 27.1115C17.3851 27.2535 10.273 27.0888 8.69256 27.0888C7.90232 27.0888 5.90226 27.0888 3.58911 27.0888C2.3707 27.0888 1.5216 27.1115 0 27.1115C0.946642 20.9157 1.42287 14.9983 2.84574 9.30792C4.30345 3.49266 10.2853 -0.1646 16.4936 9.01907e-05C22.4813 0.159101 28.0276 4.1287 29.3459 9.96668C30.5829 15.4412 31.094 21.0748 32 27.1115ZM27.8592 23.6133C27.5456 20.7794 27.2726 18.0933 26.9358 15.4185C26.7499 13.9306 26.5757 12.42 26.175 10.9775C24.9554 6.58202 21.041 3.55513 16.4995 3.3734C11.894 3.18599 7.3873 5.75289 6.21996 10.0803C5.04102 14.4474 4.69256 19.0246 3.96661 23.6189C12.2134 23.6133 19.9085 23.6133 27.8592 23.6133Z"
          fill="currentColor"
        />
        <path
          d="M27.8594 23.6188C19.9087 23.6188 12.2194 23.6188 3.9668 23.6188C4.69275 19.0302 5.04121 14.4473 6.22015 10.0802C7.38749 5.75846 11.8942 3.18589 16.4996 3.37329C21.0412 3.55502 24.9555 6.58191 26.1751 10.9774C26.5759 12.4199 26.7501 13.9305 26.9359 15.4184C27.2728 18.0989 27.5399 20.785 27.8594 23.6188Z"
          fill="none"
        />
        <path
          d="M22.1266 26.8398C22.1266 29.9407 19.2962 32.4546 15.8048 32.4546C12.3133 32.4546 9.48291 29.9407 9.48291 26.8398C9.48291 23.7388 12.3133 24.9674 15.8048 24.9674C19.2962 24.9674 22.1266 23.7388 22.1266 26.8398Z"
          fill="currentColor"
        />
        <path
          d="M12.6436 26.2727H15.8045H18.9654C18.9654 27.9798 17.5816 29.3636 15.8745 29.3636H15.7345C14.0274 29.3636 12.6436 27.9798 12.6436 26.2727Z"
          style={{ fill: "currentColor", filter: "invert(1)" }}
        />
      </g>
    </svg>
  );
};

export const PlusIcon = () => {
  return (
    <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.382812"
        y="7.20587"
        width="15.8824"
        height="1.58824"
        rx="0.794118"
        fill="currentColor"
      />
      <rect
        x="9.11816"
        y="0.0588379"
        width="15.8824"
        height="1.58824"
        rx="0.794118"
        transform="rotate(90 9.11816 0.0588379)"
        fill="currentColor"
      />
    </svg>
  );
};
