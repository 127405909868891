import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import style from "./NotFound.module.scss";

import error1 from "./../../assets/New/Images/error404/404-1.svg";
import error2 from "./../../assets/New/Images/error404/404-2.svg";
import error3 from "./../../assets/New/Images/error404/404-3.svg";
import error4 from "./../../assets/New/Images/error404/404-4.svg";
import error5 from "./../../assets/New/Images/error404/404-5.svg";
import error6 from "./../../assets/New/Images/error404/404-6.svg";

const NotFound = () => {
  const navigate = useNavigate();

  const errors = [error1, error2, error3, error4, error5, error6];

  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(
      () => {
        setImageIndex((prev) => (prev + 1) % errors.length);
      },
      imageIndex === 0 ? 1500 : 80,
    );

    return () => {
      clearInterval(interval);
    };
  }, [errors]);

  return (
    <div className={style.container}>
      <div className={style.content}>
        <h3>Whoops!</h3>
        <h5>Sorry, this page is not found.</h5>

        <div className={style.error}>
          <img src={errors[imageIndex]} alt="Error 404" draggable="false" />
        </div>

        <button className={style.button} onClick={() => navigate("/")}>
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default NotFound;
