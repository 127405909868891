import * as yup from "yup";

import { validateEmail } from "../../helpers/string";

export const registerValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("First Name is required")
    .trim()
    .min(2, "First Name must be at least 2 characters long")
    .max(30, "First Name must be less than 30 characters long"),
  lastName: yup
    .string()
    .required("Last Name is required")
    .min(2, "Last Name must be at least 2 characters long")
    .max(30, "Last Name must be less than 30 characters long")
    .trim(),
  email: yup
    .string()
    .trim()
    .test("email", "Invalid email", (value) => validateEmail(value)),
  password: yup.string().trim().required("Password is required"),
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});

export const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .test("email", "Invalid email", (value) => validateEmail(value)),
  password: yup.string().trim().required("Password is required").min(8),
});
