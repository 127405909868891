import OneSignal from "react-onesignal";
import { APP_ID_ONESIGNAL } from "../constants";

export default async function runOneSignal() {
  await OneSignal.init({
    appId: APP_ID_ONESIGNAL,
    allowLocalhostAsSecureOrigin: true,
    serviceWorkerPath: "/OneSignalSDKWorker.js",
  });
}

export const setPlayerId = async (user_id: string) => {
  await OneSignal.setExternalUserId(user_id);
};

export const getNotificationPermission = async () => {
  await OneSignal.showSlidedownPrompt({
    force: true,
  });
  await OneSignal.showNativePrompt();
};
