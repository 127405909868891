import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { api } from "../api/api";
import { fetchUserInfo } from "./userSlice";
import { COMPANY_LINK } from "../constants";

const initialState = {
  companyLink: {
    isLinked: false,
    loading: false,
    error: null,
  },
};

export const companyLinkSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    fetchCompanyLinkStart(state, action) {
      state.companyLink.loading = action.payload;
    },
    fetchCompanyLinkSuccess(state) {
      state.companyLink.isLinked = true;
      state.companyLink.loading = false;
      state.companyLink.error = null;
    },
    fetchCompanyLinkFailure(state, action) {
      state.companyLink.loading = false;
      state.companyLink.error = action.payload;
    },

    fetchCompanyUnlinkStart(state) {
      state.companyLink.loading = true;
      state.companyLink.error = null;
    },
    fetchCompanyUnlinkSuccess(state) {
      state.companyLink.isLinked = false;
      state.companyLink.loading = false;
      state.companyLink.error = null;
    },
    fetchCompanyUnlinkFailure(state, action) {
      state.companyLink.loading = false;
      state.companyLink.error = action.payload;
    },
  },
});

export const fetchCompanyLink = (companyCode: string) => async (dispatch) => {
  try {
    dispatch(fetchCompanyLinkStart(true));
    const response = await api.post("/user/api/account/company/link", {
      code: companyCode,
    });
    dispatch(fetchCompanyLinkSuccess(response.data));
    dispatch(fetchUserInfo({}));
    if (!localStorage.getItem(COMPANY_LINK)) {
      localStorage.setItem(COMPANY_LINK, companyCode);
    }
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch(fetchCompanyLinkFailure(error.message));
  }
};
export const fetchCompanyUnlink = () => async (dispatch) => {
  try {
    dispatch(fetchCompanyUnlinkStart());
    await api.delete("/user/api/account/company/unlink");
    dispatch(fetchCompanyUnlinkSuccess());
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch(fetchCompanyUnlinkFailure(error.message));
  }
};

export const {
  fetchCompanyLinkStart,
  fetchCompanyLinkSuccess,
  fetchCompanyLinkFailure,
  fetchCompanyUnlinkStart,
  fetchCompanyUnlinkSuccess,
  fetchCompanyUnlinkFailure,
} = companyLinkSlice.actions;

export default companyLinkSlice.reducer;
