import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { MEMBERS_LIMIT } from "../Pages/Groups/SettingsGroup/SettingsGroup";
import { api } from "../api/api";
import { GROUPS_API } from "../api/requests";

import {
  FetchGroupsProps,
  GroupDetailsType,
  GroupMemberType,
  GroupType,
  SelectedTabType,
  StarredMessageType,
} from "../types";

export const GROUPS_LIMIT = 15;
const STARRED_MESSAGES_LIMIT = 20;

interface GroupSliceState {
  groups: GroupType[];
  hasMoreGroups: boolean;
  loading: boolean;
  pageGroups: number;
  forwardMessageGroups: GroupType[];
  forwardMessageLoading: boolean;
  forwardMessageHasMoreGroups: boolean;
  forwardMessagePage: number;
  hasAvailableGroups: null | boolean;
  starredMessages: StarredMessageType[];
  starredMessagesLoading: boolean;
  hasMoreStarredMessages: boolean;
  members: GroupMemberType[];
  hasMoreGroupMembers: boolean;
  groupDetails: GroupDetailsType;
  count: any;
  error: any;
  allRequestCount: any;
  groupTab: SelectedTabType;
}

const initialState: GroupSliceState = {
  groups: [],
  hasMoreGroups: true,
  pageGroups: 1,
  loading: false,
  forwardMessageGroups: [],
  forwardMessageLoading: false,
  forwardMessageHasMoreGroups: true,
  forwardMessagePage: 1,
  hasAvailableGroups: null,
  starredMessages: [],
  starredMessagesLoading: false,
  hasMoreStarredMessages: true,
  members: [],
  hasMoreGroupMembers: false,
  groupDetails: null,
  count: {},
  error: null,
  allRequestCount: {},
  groupTab: "sidebar",
};

export const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setHasAvailableGroups(state, action: PayloadAction<boolean>) {
      state.hasAvailableGroups = action.payload;
    },
    setForwardMessageLoading(state, action: PayloadAction<boolean>) {
      state.forwardMessageLoading = action.payload;
    },
    setPageGroups(state, action: PayloadAction<number>) {
      state.pageGroups = action.payload;
    },
    setForwardMessagePage(state, action: PayloadAction<number>) {
      state.forwardMessagePage = action.payload;
    },
    setGroups(state, action: PayloadAction<GroupType[]>) {
      state.error = null;
      state.groups = action.payload;
    },
    setForwardMessageGroups(state, action: PayloadAction<GroupType[]>) {
      state.error = null;
      state.forwardMessageGroups = action.payload;
    },
    setHasMoreStarredMessages(state, action: PayloadAction<boolean>) {
      state.hasMoreStarredMessages = action.payload;
    },
    setHasMoreGroups(state, action: PayloadAction<boolean>) {
      state.hasMoreGroups = action.payload;
    },
    setForwardMessageHasMoreGroups(state, action: PayloadAction<boolean>) {
      state.forwardMessageHasMoreGroups = action.payload;
    },
    setStarredMessages(state, action: PayloadAction<any>) {
      state.error = null;
      state.starredMessages = action.payload;
    },
    setStarredMessagesLoading(state, action: PayloadAction<boolean>) {
      state.starredMessagesLoading = action.payload;
    },
    setGroupDetails(state, action: PayloadAction<GroupDetailsType>) {
      state.error = null;
      state.groupDetails = action.payload;
    },
    getGroupMembers(state, action: PayloadAction<any>) {
      state.error = null;
      state.members = action.payload;
    },
    setHasMoreGroupMembers(state, action: PayloadAction<boolean>) {
      state.error = null;
      state.hasMoreGroupMembers = action.payload;
    },
    setInviteRequestCount(state, action: PayloadAction<any>) {
      state.error = null;
      state.allRequestCount = action.payload;
    },
    setGroupTab(state, action: PayloadAction<SelectedTabType>) {
      state.error = null;
      state.groupTab = action.payload;
    },
  },
});

export const fetchGroups = ({
  page = 1,
  search,
  isForwardMessage,
  signal,
}: FetchGroupsProps): any => {
  return async (dispatch, getState) => {
    const previousGroups = isForwardMessage
      ? getState().group.forwardMessageGroups
      : getState().group.groups;
    const hasAvailableGroups = getState().group.hasAvailableGroups;
    const isFirstPage = page === 1;

    if (isFirstPage) {
      if (isForwardMessage) {
        dispatch(setForwardMessageLoading(true));
        dispatch(setForwardMessageHasMoreGroups(true));
      } else {
        dispatch(setLoading(true));
        dispatch(setHasMoreGroups(true));
      }
    }

    try {
      const res = await GROUPS_API.getJoinedGroups(
        page,
        GROUPS_LIMIT,
        search,
        signal,
      );
      if (hasAvailableGroups === null) {
        dispatch(setHasAvailableGroups(Boolean(res?.joined_groups?.length)));
      }

      const newGroups = isFirstPage
        ? res?.joined_groups
        : [...previousGroups, ...res?.joined_groups];

      // if (res?.status === 403) {
      //   dispatch(setForwardMessageLoading(false));
      //   dispatch(setLoading(false));
      //   return;
      // }

      const hasMoreGroups = res?.joined_groups?.length === GROUPS_LIMIT;

      if (isForwardMessage) {
        dispatch(setForwardMessageGroups(newGroups));
        dispatch(setForwardMessageHasMoreGroups(hasMoreGroups));
        dispatch(setForwardMessagePage(page + 1));
      } else {
        dispatch(setGroups(newGroups));
        dispatch(setHasMoreGroups(hasMoreGroups));
        dispatch(setPageGroups(page + 1));
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "ERROR");
    } finally {
      dispatch(setForwardMessageLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const fetchCurrentGroup =
  (groupId: string): any =>
  async (dispatch) => {
    try {
      const res = await GROUPS_API.getGroupDetails(groupId);
      dispatch(setGroupDetails(res));
    } catch (error) {
      toast.error(error.response?.data?.message || "ERROR");
    }
  };

export const fetchStarredMessagesList =
  (groupId: string, key?: string): any =>
  async (dispatch, getState) => {
    try {
      dispatch(setHasMoreStarredMessages(true));
      const prevMessages = getState().group.starredMessages;

      dispatch(setStarredMessagesLoading(true));
      const data = await GROUPS_API.getStarredMessages(
        groupId,
        STARRED_MESSAGES_LIMIT,
        key,
      );
      if (data?.group_starred_messages)
        dispatch(
          setHasMoreStarredMessages(
            data?.group_starred_messages?.length === STARRED_MESSAGES_LIMIT,
          ),
        );
      dispatch(
        setStarredMessages(
          key
            ? [...prevMessages, ...data?.group_starred_messages]
            : data?.group_starred_messages,
        ),
      );
    } catch (error) {
      toast.error(error.response?.data?.message || "ERROR");
    } finally {
      dispatch(setStarredMessagesLoading(false));
    }
  };

export const ExitGroup =
  (id: any): any =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      await api.put(`/social/api/group/${id}/exit`);
      dispatch(fetchGroups({}));
    } catch (error) {
      toast.error(error.response?.data?.message || "ERROR");
    } finally {
      dispatch(setLoading(false));
    }
  };

export const fetchGroupMembers =
  (groupId: string, limit: number): any =>
  async (dispatch) => {
    try {
      dispatch(setHasMoreGroupMembers(true));

      const response = await api.get(`/social/api/group/${groupId}/members/`, {
        params: {
          limit,
        },
      });
      if (response.status === 403) {
        toast.error("Forbidden");
      } else {
        dispatch(getGroupMembers(response.data.group_members));
        dispatch(
          setHasMoreGroupMembers(
            response.data.group_members.length === MEMBERS_LIMIT,
          ),
        );
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "ERROR");
    }
  };

export const fetchMoreGroupMembers =
  (groupId: string, limit: number, key: string): any =>
  async (dispatch, getState) => {
    try {
      dispatch(setHasMoreGroupMembers(true));
      const members = getState().group.members;

      const response = await api.get(`/social/api/group/${groupId}/members/`, {
        params: {
          limit,
          key,
        },
      });
      if (response.status === 403) {
        toast.error("Forbidden");
      } else {
        dispatch(getGroupMembers([...members, ...response.data.group_members]));
        dispatch(
          setHasMoreGroupMembers(
            response.data.group_members.length === MEMBERS_LIMIT,
          ),
        );
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "ERROR");
    }
  };

export const inviteToGroup =
  (data, groupID): any =>
  async (dispatch) => {
    try {
      const response = await api.post(
        `/social/api/group/${groupID}/invite`,
        data,
      );
      if (response.status === 200) {
        toast.success("Invite send successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

export const {
  setLoading,
  setForwardMessageLoading,
  setForwardMessageHasMoreGroups,
  setPageGroups,
  setForwardMessagePage,
  setGroups,
  setForwardMessageGroups,
  setHasAvailableGroups,
  setGroupDetails,
  setStarredMessagesLoading,
  getGroupMembers,
  setHasMoreGroupMembers,
  setHasMoreStarredMessages,
  setInviteRequestCount,
  setStarredMessages,
  setHasMoreGroups,
  setGroupTab,
} = groupSlice.actions;

export default groupSlice.reducer;
