import { formatDistance } from "date-fns";
import { Dispatch, FC, SetStateAction } from "react";
import { useNavigate } from "react-router";

import classNames from "../../../../hooks/classNames";
import { useAppDispatch } from "../../../../hooks/redux-hooks";
import { markNotificationSeen } from "../../../../Slice/userSlice";
import Avatar from "../../../Avatar/Avatar";
import HighlightLinks from "../../../HighlightLinks/HighlightLinks";

import {
  AndyAutoSessionMetadata,
  GroupAutoJoinMetadata,
  Notification,
  PostCommentMetadata,
} from "../../../../types";

import style from "./NotificationItem.module.scss";

interface NotificationItemProps {
  notification: Notification;
  setNotification: Dispatch<SetStateAction<boolean>>;
}

const NotificationItem: FC<NotificationItemProps> = ({
  notification,
  setNotification,
}) => {
  const metaData =
    notification?.metadata?.comment_like ||
    notification?.metadata?.comment_reply ||
    notification?.metadata?.post_comment ||
    notification?.metadata?.post_like ||
    notification?.metadata?.andy_auto_session_message ||
    notification?.metadata?.auto_group_join;

  const userMeta = metaData?.user_meta;

  if (!notification.metadata) return null;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = (
    el: Notification,
    metaData:
      | PostCommentMetadata
      | AndyAutoSessionMetadata
      | GroupAutoJoinMetadata,
  ) => {
    setNotification(false);
    if (!el.is_seen) {
      dispatch(markNotificationSeen(el._id));
    }
    if (!metaData) return;
    if ("post_id" in metaData) {
      const post_id = metaData.post_id;
      navigate(`/feed?post=${post_id}`);
    } else if ("group_id" in metaData) {
      const group_id = metaData.group_id;
      navigate(`/group?groupId=${group_id}`);
    } else {
      navigate("/");
    }
  };

  return (
    <div
      onClick={() => {
        handleClick(notification, metaData);
      }}
      className={classNames(
        style.option,
        notification?.is_seen && style.isSeen,
      )}
    >
      <Avatar
        userMeta={userMeta}
        className={style.optionAvatarWrapper}
        isBot={!!notification?.metadata?.andy_auto_session_message || false}
      />

      <div>
        <p className={style.notificationContent}>
          <HighlightLinks
            text={notification?.content || ""}
            linkStyles={style.messageLink}
            githubSyntax
          />
        </p>
        <span className={style.notificationDate}>
          {notification?.createdAt &&
            formatDistance(new Date(notification.createdAt), new Date(), {
              addSuffix: true,
            })}
        </span>
      </div>
    </div>
  );
};

export default NotificationItem;
