import { FC } from "react";

import { useAppSelector } from "../../../hooks/redux-hooks";
import { selectTheme } from "../../../Slice/userSlice";

import style from "./SidebarActions.module.scss";

interface SidebarActionsProps {
  handleLogoutClick: () => void;
  handleRelinkClick: () => void;
  handleUnlinkClick: () => void;
  savedCompanyLink: string | null;
}

const SidebarActions: FC<SidebarActionsProps> = ({
  handleLogoutClick,
  handleRelinkClick,
  handleUnlinkClick,
  savedCompanyLink,
}) => {
  const info = useAppSelector((state) => state.user.info);
  const { isLinked } = useAppSelector(selectTheme);

  return (
    <div className={style.actionsButton}>
      <button type="button" onClick={handleLogoutClick}>
        Logout
      </button>
      {!info?.linked_company_id && savedCompanyLink && (
        <button type="button" onClick={handleRelinkClick}>
          Relink
        </button>
      )}
      {isLinked && (
        <button type="button" onClick={handleUnlinkClick}>
          Unlink
        </button>
      )}
    </div>
  );
};

export default SidebarActions;
