import React, { ComponentPropsWithoutRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import classNames from "../../hooks/classNames";

import style from "./Toggle.module.scss";

interface ToggleProps extends ComponentPropsWithoutRef<"input"> {
  register?: UseFormRegisterReturn;
  className?: string;
}

const Toggle: React.FC<ToggleProps> = ({ register, className, ...props }) => {
  return (
    <label className={style.switch}>
      <input type="checkbox" {...props} {...register} />
      <span
        className={classNames(
          style.slider,
          props?.checked && style.active,
          className
        )}
      />
    </label>
  );
};

export default Toggle;
