import { FC } from "react";

import { firstLetterCapitalize } from "../../../../helpers/string";
import Avatar from "../../../../Components/Avatar/Avatar";
import { GroupMemberType } from "../../../../types";

import style from "./MemberItem.module.scss";

interface MemberItemProps {
  member: GroupMemberType;
  openProfile: (currentUserId?: string) => void;
}

const MemberItem: FC<MemberItemProps> = ({ member, openProfile }) => {
  return (
    <div className={style.member} onClick={() => openProfile(member.user_id)}>
      <div className="d-flex align-items-center">
        <Avatar userMeta={member?.user_meta} />
        <h5 className={style.name}>{member?.user_meta?.first_name}</h5>
      </div>
      <h5>{firstLetterCapitalize(member.role)}</h5>
    </div>
  );
};

export default MemberItem;
