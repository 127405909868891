import { PayloadAction, createSlice, createSelector } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { RootState } from "../Store/store";
import { api } from "../api/api";
import { USER_API } from "../api/requests";
import { COOKIE_TOKEN_NAME, deleteCookie, setCookie } from "../helpers/cookies";
import {
  getCompanyId,
  getPrimary,
  getSecondary,
  setCompanyId,
  setPrimary,
  setSecondary,
} from "../helpers/localStorage";

import { Notification } from "../types";

interface UserSliceState {
  auth: {
    access_token: string;
    refresh_token: string;
  };
  notifications: Notification[];
  hasMoreNotifications: boolean;
  newMessages: number;
  info: {
    user_id: string;
    email: string;
    first_name: string;
    last_name: string;
    education_level: any;
    fields_of_study: any[];
    country: any;
    active: boolean;
    account_type: string;
    oauth_id: any;
    subscription_plan: string;
    credits: number;
    createdAt: string;
    region_code: string;
    description: string;
    linked_company_id: string;
    profile_image_url: string;
    username: string;
    linked_company_meta: {
      name: string;
      logo_url: string;
      code: string;
      theme: {
        primary_colour: string;
        secondary_color: string;
      };
    };
  };
  uploadSignature: {};
  theme: "dark" | "light";
  loading: boolean;
  error: any;
  historyStack: any[];
}

const initialState: UserSliceState = {
  auth: {
    access_token: "",
    refresh_token: "",
  },
  notifications: [] as Notification[],
  hasMoreNotifications: true,
  newMessages: 0,
  info: {
    user_id: "",
    email: "",
    first_name: "",
    last_name: "",
    education_level: null,
    fields_of_study: [],
    country: null,
    active: false,
    account_type: "",
    oauth_id: null,
    subscription_plan: "",
    credits: 0,
    createdAt: "",
    region_code: "",
    description: "",
    linked_company_id: "",
    profile_image_url: "",
    username: "",
    linked_company_meta: {
      name: "",
      logo_url: "",
      code: "",
      theme: {
        primary_colour: "",
        secondary_color: "",
      },
    },
  },
  uploadSignature: {},
  theme: "dark",
  loading: false,
  error: null,
  historyStack: [],
};

export const fetchData =
  ({ email, password, navigate, previousRoute }: any): any =>
  async (dispatch) => {
    try {
      dispatch(fetchDataStart(true));
      const response = await USER_API.postLogin({ email, password });
      dispatch(fetchDataStart(false));
      if (response.data === "401:Unauthorized") {
        deleteCookie(COOKIE_TOKEN_NAME);
        navigate("/auth/login");
        toast.error("Incorrect email or password");
        return;
      }
      dispatch(fetchDataSuccess(response.data));
      navigate(previousRoute || "/");
    } catch (error) {
      dispatch(fetchDataStart(false));
      toast.error(error.response?.data?.message || "Error");
    }
  };

export const fetchUserInfo =
  ({ navigate }: any): any =>
  async (dispatch) => {
    try {
      dispatch(fetchDataStart(true));
      const response = await api.get("/user/api/account/profile");
      if (response.status === 403) {
        navigate("/auth/login");
        dispatch(fetchDataStart(false));
      } else {
        dispatch(userInfoSuccess(response.data));
        const { linked_company_id, linked_company_meta } = response.data;
        if (linked_company_id) {
          setCompanyId(linked_company_id);
          dispatch(setAppTheme("dark"));
        }
        if (linked_company_meta) {
          const { primary_colour, secondary_color } = linked_company_meta.theme;
          setPrimary(primary_colour);
          setSecondary(secondary_color);
        }
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
      dispatch(fetchDataStart(false));
    }
  };

export const fetchUserNotifications =
  (params: { type?: string; key?: string }): any =>
  async (dispatch) => {
    try {
      const response = await api.get("/social/api/notification", { params });
      dispatch(setNotifications(response.data as Notification[]));
    } catch (error) {
      toast.error(error.response?.data?.message || "Error");
    }
  };

export const fetchUserNotificationsNew = () => async (dispatch) => {
  try {
    const response = await api.get("/social/api/notification");
    dispatch(
      updateNotificationsList(response.data.slice(0, 1) as Notification[]),
    );
  } catch (error) {
    toast.error(error.response?.data?.message || "Error");
  }
};

export const markNotificationSeen =
  (notification_id: string): any =>
  async (dispatch) => {
    try {
      await api.put(`/social/api/notification/${notification_id}/seen`);
      dispatch(markSeenNotification(notification_id));
    } catch (error) {
      toast.error(error.response?.data?.message || "Error");
    }
  };

export const getUnreadNotifications = async () => {
  try {
    const response = await api.get<Notification[]>(
      "/social/api/notification",
      {},
    );
    return response.data.filter((notification) => !notification.is_seen).length;
  } catch (error) {
    toast.error("Could not fetch notifications");
  }
};

export const getUploadSignature = (): any => async (dispatch) => {
  try {
    const response = await api.get(`/media/api/account/upload/signature`, {
      params: {
        // filename:
        //   "e59e4847-26d2-4a7d-a48f-d3c2d2fd8ae3-1682191652-default.file.csv",
      },
    });
    dispatch(setUploadSignature(response.data));
    return response.data;
  } catch (err) {
    console.error(err.message);
    toast.error(err.message || "Upload Signature Error!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const createDocument: any = (data) => async () => {
  try {
    const response = await api.post(`/media/api/account/document`, data);
    if (response?.data?.msg) {
      if (response.status === 200) {
        toast.info(response?.data?.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`Error! ${response?.data?.msg}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    return response.data;
  } catch (err) {
    console.error(err.message);
    toast.error(err.message || "Upload Signature Error!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const createMedia: any = (data) => async () => {
  try {
    const response = await api.post(`/media/api/account/audio`, data);
    if (response.status === 200) {
      toast.info("Media Created", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(`Error! ${response?.data?.msg}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    return response.data;
  } catch (err) {
    console.error(err.message);
    toast.error(err.message || "Upload Signature Error!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getLinkedFromStorage(state) {
      state.info.linked_company_id = getCompanyId();
      if (state?.info.linked_company_meta?.theme) {
        state.info.linked_company_meta.theme.primary_colour = getPrimary();
        state.info.linked_company_meta.theme.secondary_color = getSecondary();
      }
    },
    fetchDataStart(state, action) {
      state.loading = action.payload;
    },
    fetchDataSuccess(state, action: PayloadAction<any>) {
      state.info = action.payload.user;
      state.auth = action.payload.auth;
      setCookie(COOKIE_TOKEN_NAME, action.payload.auth?.access_token);
      state.loading = false;
      state.error = null;
    },
    userInfoSuccess(state, action: PayloadAction<any>) {
      state.info = action.payload;
      state.error = null;
      state.loading = false;
    },
    setUploadSignature(state, action: PayloadAction<any>) {
      state.uploadSignature = action.payload;
      state.loading = false;
    },
    setAppTheme(state, action: PayloadAction<"dark" | "light">) {
      state.theme = action.payload;
    },
    setNotifications(state, action: PayloadAction<Notification[]>) {
      if (state.notifications.length === 0) {
        state.notifications = action.payload;
      } else {
        state.notifications = [...state.notifications, ...action.payload];
      }
      if (action.payload.length < 10) {
        state.hasMoreNotifications = false;
      }
      state.newMessages = state.notifications.filter(
        (el) => !el.is_seen,
      ).length;
    },
    clearNotifications(state) {
      state.notifications = [];
      state.hasMoreNotifications = true;
    },
    updateNotificationsList(state, action: PayloadAction<Notification[]>) {
      state.notifications = [
        ...action.payload,
        ...state.notifications.slice(0, state.notifications.length - 1),
      ];
    },
    initNotifications(state, action: PayloadAction<number>) {
      state.newMessages = action.payload;
    },
    markSeenNotification(state, action: PayloadAction<string>) {
      state.notifications = state.notifications.map((el) =>
        el._id === action.payload ? { ...el, is_seen: true } : el,
      );
      state.newMessages = state.newMessages - 1;
    },
    addRouteToHistoryStack(state, action: PayloadAction<string>) {
      state.historyStack = [...state.historyStack, action.payload];
    },
  },
});

const getLinked = (state: RootState) => state.user?.info?.linked_company_id;
const getTheme = (state: RootState) => state.user.theme;
const getHistoryStack = (state: RootState) => state.user.historyStack;

export const getPreviousRoute = createSelector(
  getHistoryStack,
  (historyStack) =>
    historyStack.filter((el) => !el.includes("/auth")).slice(-1)[0],
);

export const selectTheme = createSelector(
  getLinked,
  getTheme,
  (linkedCompanyId, theme) => ({
    isLinked: Boolean(linkedCompanyId),
    isLightTheme: theme === "light",
  }),
);

export const {
  fetchDataStart,
  fetchDataSuccess,
  userInfoSuccess,
  setUploadSignature,
  setAppTheme,
  getLinkedFromStorage,
  setNotifications,
  markSeenNotification,
  clearNotifications,
  initNotifications,
  addRouteToHistoryStack,
  updateNotificationsList,
} = userSlice.actions;

export default userSlice.reducer;
