import { CurrencyType } from "../types";

import bnbIcon from "../assets/New/Icons/BNB.svg";
import ethereumIcon from "../assets/New/Icons/Ethereum.svg";
import polygonIcon from "../assets/New/Icons/Polygon.svg";
import usdtIcon from "../assets/New/Icons/USDT.svg";
import busdIcon from "../assets/New/Icons/busd.svg";
import usdcIcon from "../assets/New/Icons/usdc.svg";

export const API_BASE_URL: string = process.env.API_BASE_URL;
export const COMPANY_LINK = "company-link";
export const PUSHER_ENV = process.env.PUSHER_ENV;
export const PRODUCTION = process.env.APP_ENV === "production";
export const APP_ID_ONESIGNAL = process.env.APP_ID_ONESIGNAL;
export const SSE_CLIENT_LINK = process.env.SSE_CLIENT_LINK;

export const MULTIMODAL_HISTORY_LIMIT = 50;
export const MOCK_USER_MESSAGE_ID = "sdg235fs2dfsaad";
export const MOCK_BOT_MESSAGE_ID = "fghs32hl235h2u5g2h";

export const CHAINLIST = [
  {
    chainName: "Polygon Mainnet",
    chainId: "0x89",
    nativeCurrency: {
      name: "MATIC",
      decimals: 18,
      symbol: "MATIC",
    },
    rpcUrls: ["https://polygon-rpc.com/"],
  },
  {
    chainName: "BNB Smart Chain Mainnet",
    chainId: "0x38",
    nativeCurrency: {
      name: "BNB",
      decimals: 18,
      symbol: "BNB",
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
  },
  {
    chainName: "Ethereum Mainnet",
    chainId: "0x1",
    nativeCurrency: {
      name: "ETH",
      decimals: 18,
      symbol: "ETH",
    },
    rpcUrls: ["https://mainnet.infura.io/v3/{projectId}"],
  },
  {
    chainName: "Goerli",
    chainId: "0x5",
    nativeCurrency: {
      name: "GöETH",
      decimals: 18,
      symbol: "GöETH",
    },
    rpcUrls: ["https://goerli.infura.io/v3/{projectId}"],
  },
  {
    chainName: "Mumbai",
    chainId: "0x13881",
    nativeCurrency: {
      name: "tMATIC",
      decimals: 18,
      symbol: "tMATIC",
    },
    rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
  },
  {
    chainName: "BNB Smart Chain Testnet",
    chainId: "0x61",
    nativeCurrency: {
      name: "tBNB",
      decimals: 18,
      symbol: "tBNB",
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
  },
];

export const ABI = [
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [
      {
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_spender",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_from",
        type: "address",
      },
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [
      {
        name: "",
        type: "uint8",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [
      {
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
      {
        name: "_spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    payable: true,
    stateMutability: "payable",
    type: "fallback",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
];

export const currentNetwork = PRODUCTION ? "mainnet" : "testnet";

export const CRYPTO_LIST: CurrencyType[] = [
  {
    name: "USDT",
    coin: "USDT",
    icon: usdtIcon,
  },
  {
    name: "Polygon",
    coin: "MATIC",
    icon: polygonIcon,
  },
  {
    name: "BNB",
    coin: "BNB",
    icon: bnbIcon,
  },
  {
    name: "Ethereum",
    coin: "ETH",
    icon: ethereumIcon,
  },
  {
    name: "BUSD",
    coin: "BUSD",
    icon: busdIcon,
  },
  {
    name: "USDC",
    coin: "USDC",
    icon: usdcIcon,
  },
];

export const NETWORKS = ["binance", "ethereum", "polygon"];

export const LINK_REGEX =
  /^(?:https:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gi;
export const YOUTUBE_REGEX =
  /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/;

export const FILE_ACTION_LIST = [
  "summarize",
  "checkPlagiarism",
  "downloadAudio",
  "viewTranscript",
] as const;

export const DOCUMENT_EXTENSIONS = [
  "docx",
  "doc",
  "pdf",
  "epub",
  "txt",
  "ppt",
  "pptx",
  "xls",
  "xlsx",
  "csv",
];
export const IMAGE_EXTENSIONS = ["png", "jpg", "jpeg", "heif", "heic"];
export const AUDIO_EXTENSIONS = ["m4a", "mp3", "wav", "mp4", "webm"];

export const FILE_EXTENSIONS = [
  ...DOCUMENT_EXTENSIONS,
  ...IMAGE_EXTENSIONS,
  ...AUDIO_EXTENSIONS,
];

export const MEDIA_LIMIT = 30;
export const COMPLETED = "completed";
export const PROCESSING = "processing";
export const FAILED = "failed";
export const DOCUMENTS_LIMIT = 30;
export const NOT_APPLICABLE = "not_applicable";
export const INIT = "init";

export const MOCK_MESSAGE_ID = "MOCK_MESSAGE_ID";
