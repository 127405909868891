import { Dispatch, FC, SetStateAction } from "react";

import style from "./ToggleSidebarBtn.module.scss";

interface ToggleSidebarBtnProps {
  sidebarIsOpen: boolean;
  setSidebarIsOpen: Dispatch<SetStateAction<boolean>>;
}

const ToggleSidebarBtn: FC<ToggleSidebarBtnProps> = ({
  sidebarIsOpen,
  setSidebarIsOpen,
}) => (
  <div
    className={sidebarIsOpen ? style.open : style.menuButton}
    onClick={() => setSidebarIsOpen((prev) => !prev)}
    id="toggleSidebar"
    title="Toggle sidebar"
  >
    <div className={style.barsWrapper}>
      <div className={style.topBar} />
      <div className={style.middleBar} />
      <div className={style.bottomBar} />
    </div>
  </div>
);

export default ToggleSidebarBtn;
