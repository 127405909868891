import axios, { AxiosError } from "axios";

import { API_BASE_URL } from "../constants";
import { COOKIE_TOKEN_NAME, deleteCookie, getCookie } from "../helpers/cookies";

export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  async (config: any) => {
    const value = getCookie(COOKIE_TOKEN_NAME);
    if (value) config.headers.Authorization = `Bearer ${value}`;

    return config;
  },
  (err: any) => {
    return Promise.reject(err);
  }
);

api.interceptors.response.use(
  (res: any) => res,
  (err: any) => {
    if (
      err.response &&
      err.response.status === 401 &&
      !err.request.responseURL.includes("verify_login_token") &&
      !err.request.responseURL.includes("/user/auth/authtoken")
    ) {
      deleteCookie(COOKIE_TOKEN_NAME);
      window.location.reload();
    } else if (err.response && err.response.status === 409) {
      const errorMessage = "Sorry, you have insufficient credits";
      const modifiedError = new AxiosError(errorMessage);
      modifiedError.response = err.response;
      throw modifiedError;
    }
    return Promise.reject(err);
  }
);

export default api;
