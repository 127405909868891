import { useSearchParams } from "react-router-dom";

import style from "./Crypto.module.scss";

const Crypto = () => {
  const [searchParams] = useSearchParams();
  const queryParameterValue = [...searchParams.values()][0];

  return <h1 className={style.heading}>{queryParameterValue}</h1>;
};

export default Crypto;
