import { ComponentPropsWithoutRef, useEffect, useRef, useState } from "react";

interface AsyncImageProps extends ComponentPropsWithoutRef<"img"> {
  shouldLoad: boolean;
  src: string;
  alt: string;
}

const AsyncImage = ({ shouldLoad, src, alt, ...props }: AsyncImageProps) => {
  const [loadedSrc, setLoadedSrc] = useState("");

  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!shouldLoad) return;

    setLoadedSrc(src);
  }, [shouldLoad, src]);

  return <img src={loadedSrc} alt={alt} {...props} ref={imageRef} />;
};

export default AsyncImage;
