import { configureStore } from "@reduxjs/toolkit";

import companyLinkSlice from "../Slice/companyLinkSlice";
import cryptoSlice from "../Slice/cryptoSlice";
import directSlice from "../Slice/dmSlice";
import gameSlice from "../Slice/gameSlice";
import groupSlice from "../Slice/groupSlice";
import homeSlice from "../Slice/homeSlice";
import knowledgeSlice from "../Slice/knowledgeSlice";
import userSlice from "../Slice/userSlice";

const store = configureStore({
  reducer: {
    user: userSlice,
    home: homeSlice,
    group: groupSlice,
    isLinked: companyLinkSlice,
    game: gameSlice,
    direct: directSlice,
    crypto: cryptoSlice,
    knowledge: knowledgeSlice,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
