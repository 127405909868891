import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import classNames from "../../../hooks/classNames";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import {
  clearNotifications,
  fetchUserNotifications,
} from "../../../Slice/userSlice";
import NotificationItem from "./NotificationItem/NotificationItem";
import NotificationSkeleton from "./NotificationSkeleton";

import { Notification } from "../../../types";

import style from "./Notifications.module.scss";

interface NotificationProps {
  setNotification: Dispatch<SetStateAction<boolean>>;
  notifications: Notification[];
}

const Notifications: FC<NotificationProps> = ({
  notifications,
  setNotification,
}) => {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const hasMoreNotifications = useAppSelector(
    (state) => state.user.hasMoreNotifications,
  );

  useEffect(() => {
    async function fetchNotifications() {
      setIsLoading(true);
      await dispatch(fetchUserNotifications({}));
      setIsLoading(false);
    }
    fetchNotifications();

    return () => {
      dispatch(clearNotifications());
    };
  }, []);

  useEffect(() => {
    if (page !== 1) {
      dispatch(
        fetchUserNotifications({
          key: notifications[notifications.length - 1]._id,
        }),
      );
    }
  }, [page]);

  const incrementPage = async () => {
    setPage((prev) => prev + 1);
  };

  return (
    <>
      {isLoading ? (
        <div id="notificationContainer" className={style.menu}>
          {Array.from(Array(10).keys()).map((_, index) => (
            <NotificationSkeleton key={index} />
          ))}
        </div>
      ) : notifications.length ? (
        <div
          className={classNames(
            style.menu,
            notifications.length > 4 && style.fixedHeight,
          )}
        >
          <InfiniteScroll
            dataLength={notifications.length}
            className={style.infiniteScroll}
            height="100%"
            next={incrementPage}
            hasMore={hasMoreNotifications}
            loader={
              <div>
                {Array.from(Array(10).keys()).map((el) => (
                  <div key={el} className={style.option}>
                    <NotificationSkeleton />
                  </div>
                ))}
              </div>
            }
          >
            {notifications.map((notification, key) => (
              <NotificationItem
                key={key}
                notification={notification}
                setNotification={setNotification}
              />
            ))}
          </InfiniteScroll>
        </div>
      ) : (
        <div className={style.noNotificationMenu}>
          <div className={style.noNotificationsOption}>
            You have no new notifications
          </div>
        </div>
      )}
    </>
  );
};

export default Notifications;
