import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  GameMemberType,
  GameResults,
  GameRoomType,
  QuestionType,
} from "../types";
import { GAME_API } from "../api/requests";
import { QUIZ_LEADERBOARD_LIMIT } from "../Pages/Game/GameRoom/LeaderBoard/LeaderBoard";
import { RootState } from "../Store/store";

interface GameSliceState {
  currentGame: GameRoomType | null;
  invitedGame: GameRoomType | null;
  currentQuestion: QuestionType;
  members: GameMemberType[];
  hasMoreMembers: boolean;
  loading: boolean;
  leaders: GameResults;
  hasMoreLeaders: boolean;
  leadersPage: number;
  isModalGameStartedOpen: boolean;
  leadersError: boolean;
}

const initialState: GameSliceState = {
  currentGame: null,
  invitedGame: null,
  currentQuestion: null,
  members: [],
  hasMoreMembers: true,
  loading: false,
  leaders: [],
  hasMoreLeaders: true,
  isModalGameStartedOpen: false,
  leadersPage: 1,
  leadersError: false,
};

export const fetchGameMembers = createAsyncThunk<
  GameMemberType[],
  { gameId: string; limit: number; key?: string },
  { state: RootState }
>("members/fetch", async ({ gameId, key, limit }, { getState, dispatch }) => {
  const response = await GAME_API.getMembers({ gameId, key, limit });

  dispatch(setHasMoreMembers(response.game_members.length === limit));

  if (!key) return response.game_members;

  const prevMembers = getState().game.members;
  return [...prevMembers, ...response.game_members];
});

export const getGameResults = createAsyncThunk<
  GameResults,
  any,
  { state: RootState }
>("results/fetch", async ({ quiz_id, game }, { getState }) => {
  const leadersPage = getState().game.leadersPage;
  const inviteGroup = getState().game.invitedGame;
  const id = game._id;

  if (inviteGroup._id === id) {
    return await GAME_API.getGameResults(
      quiz_id,
      QUIZ_LEADERBOARD_LIMIT,
      leadersPage
    );
  }
});

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    setIsModalGameStartedOpen(state, action: PayloadAction<boolean>) {
      state.isModalGameStartedOpen = action.payload;
    },
    replaceCurrentGame(state, action: PayloadAction<GameRoomType>) {
      if (state.currentGame && state.currentGame?._id === action.payload._id) {
        state.currentGame = action.payload;
      }
    },
    setCurrentGame(state, action: PayloadAction<GameRoomType | null>) {
      state.currentGame = action.payload;
    },
    setNewMember(
      state,
      action: PayloadAction<{ member: GameMemberType; game: GameRoomType }>
    ) {
      if (action.payload.game?._id === state.currentGame?._id) {
        state.members = [...state.members, action.payload.member];
      }
    },
    removeMember(
      state,
      action: PayloadAction<{ member: GameMemberType; game: GameRoomType }>
    ) {
      if (action.payload.game?._id === state.currentGame?._id) {
        state.members = state.members.filter(
          (m) => m._id !== action.payload.member._id
        );
      }
    },
    setOwnerInvitedGame(state, action: PayloadAction<GameRoomType>) {
      state.invitedGame = action.payload;
    },
    setInvitedGame(state, action: PayloadAction<GameRoomType | null>) {
      state.invitedGame = action.payload;
    },
    setInvitedGameWithModal(state, action: PayloadAction<GameRoomType | null>) {
      if (action.payload === null) {
        state.invitedGame = action.payload;
        return;
      }
      if (!state.invitedGame) {
        state.invitedGame = action.payload;
        state.isModalGameStartedOpen = true;
      }
    },
    setCurrentQuestion(state, action: PayloadAction<QuestionType | null>) {
      if (
        action.payload === null ||
        (state.invitedGame && state.invitedGame._id === action.payload?.game_id)
      ) {
        state.currentQuestion = action.payload;
      }
    },
    setHasMoreMembers(state, action: PayloadAction<boolean>): void {
      state.hasMoreMembers = action.payload;
    },
    clearLeaderBoard(state) {
      state.leaders = [];
      state.hasMoreLeaders = true;
      state.leadersPage = 1;
      state.leadersError = false;
    },
    clearLeadersError(state) {
      state.leadersError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGameMembers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGameMembers.fulfilled, (state, action) => {
        state.members = action.payload;
        state.loading = false;
      })
      .addCase(getGameResults.fulfilled, (state, action) => {
        if (!action.payload) return;

        if (state.leadersPage === 1) {
          if (action.payload.length === 0) {
            state.leadersError = true;
            toast.error("Sorry, no results were found");
          } else {
            state.leadersError = false;
          }
          state.leaders = action.payload;
        } else {
          state.leadersError = false;
          state.leaders = [...state.leaders, ...action.payload];
        }

        if (action.payload.length < QUIZ_LEADERBOARD_LIMIT) {
          state.hasMoreLeaders = false;
        } else {
          state.leadersPage = state.leadersPage + 1;
        }
      });
  },
});

export const {
  setCurrentGame,
  replaceCurrentGame,
  setNewMember,
  removeMember,
  setCurrentQuestion,
  clearLeaderBoard,
  setIsModalGameStartedOpen,
  setHasMoreMembers,
  setOwnerInvitedGame,
  setInvitedGame,
  setInvitedGameWithModal,
  clearLeadersError,
} = gameSlice.actions;

export default gameSlice.reducer;
