import { FC } from "react";

interface LeaveIconProps {
  col: string;
  width?: number;
  height?: number;
  margin?: string;
}

const LeaveIcon: FC<LeaveIconProps> = ({
  col,
  width = 31,
  height = 37,
  margin = "0",
}) => {
  return (
    <svg
      width="51"
      height="56"
      viewBox="0 0 51 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin, width, height }}
    >
      <path
        d="M1.5 27.7638C1.5 33.1778 1.64023 37.0799 2.1039 40.0035C2.56428 42.9065 3.3225 44.6959 4.45166 45.995C5.59404 47.3093 7.25665 48.2862 9.87152 49.2851C11.8127 50.0267 14.1493 50.736 17.0374 51.6126C18.072 51.9267 19.1774 52.2622 20.3607 52.6284C24.6867 53.9671 27.8689 54.2855 30.2161 53.8359C32.4744 53.4032 34.058 52.243 35.2332 50.3091C36.4527 48.3023 37.2489 45.4271 37.7235 41.595C38.1958 37.7822 38.3346 33.1654 38.3346 27.7638C38.3346 22.3623 38.1958 17.7454 37.7235 13.9327C37.2489 10.1006 36.4527 7.22541 35.2332 5.21856C34.058 3.28469 32.4744 2.12449 30.2161 1.69184C27.8689 1.24218 24.6867 1.56058 20.3607 2.89925C19.1776 3.26537 18.0724 3.60083 17.0379 3.91483C14.1496 4.79153 11.8128 5.50081 9.87151 6.2424C7.25665 7.24129 5.59404 8.21825 4.45166 9.53254C3.3225 10.8316 2.56428 12.6211 2.1039 15.5241C1.64023 18.4477 1.5 22.3498 1.5 27.7638Z"
        stroke={col}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="2.21303"
        cy="2.22108"
        rx="2.21303"
        ry="2.22108"
        transform="matrix(-1 0 0 1 30.9824 25.918)"
        fill={col}
      />
      <path
        d="M37.6211 47.7529C48.6863 47.7529 48.6863 45.0523 48.6863 28.1226C48.6863 11.193 48.6863 7.77344 37.6211 7.77344"
        stroke={col}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LeaveIcon;
