import React, { FC } from "react";

import classNames from "../../hooks/classNames";

import style from "./HighlightLinks.module.scss";

interface CustomLinkProps {
  link: string;
  text: string;
  linkStyles?: string;
}

const CustomLink: FC<CustomLinkProps> = ({ link, text, linkStyles }) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={classNames(style.link, linkStyles)}
      onClick={handleClick}
    >
      {text}
    </a>
  );
};

interface HighlightLinksProps {
  text: string;
  linkStyles?: string;
  githubSyntax?: boolean;
}

const HighlightLinks: FC<HighlightLinksProps> = ({
  text,
  linkStyles,
  githubSyntax,
}) => {
  if (!text) return null;

  const linkRegex = githubSyntax
    ? /(\[[^\]]+\]\(https?:\/\/[^\s/$.?#].[^\s]*\))/gi
    : /((?:https?|ftp):\/\/[^\s/$.?#].[^\s]*)/gi;

  const messageParts = text.split(linkRegex);

  return (
    <>
      {messageParts.map((part, i) => {
        if (part.match(linkRegex)) {
          const [text, link] = githubSyntax
            ? part.slice(1, -1).split("](")
            : [part, part];

          return (
            <CustomLink
              link={link}
              text={text}
              linkStyles={linkStyles}
              key={i}
            />
          );
        }

        return part;
      })}
    </>
  );
};

export default HighlightLinks;
