import { ChangeEventHandler, FC } from "react";

import { useAppSelector } from "../../../hooks/redux-hooks";
import Button from "../../Button/Button";

import style from "./InstituteBlock.module.scss";

interface InstituteBlockProps {
  handleCompanyCodeChange: ChangeEventHandler<HTMLInputElement>;
  handleLinkClick: () => void;
  companyCode: string;
}

const InstituteBlock: FC<InstituteBlockProps> = ({
  handleCompanyCodeChange,
  handleLinkClick,
  companyCode,
}) => {
  const info = useAppSelector((state) => state.user.info);

  return (
    <>
      {info?.linked_company_meta?.logo_url && (
        <div className={style.logoContainer}>
          <img
            src={info?.linked_company_meta?.logo_url}
            alt="School Hack Logo"
            draggable={false}
            height="40"
          />
        </div>
      )}
      {info.linked_company_id !== null ? null : (
        <>
          <div className={style.inputFieldContainer}>
            <input
              className={style.input}
              placeholder="Enter Institute code"
              value={companyCode}
              onChange={handleCompanyCodeChange}
            />
          </div>
          <Button
            variant="secondary"
            onClick={handleLinkClick}
            className={style.button}
          >
            JOIN
          </Button>
        </>
      )}
    </>
  );
};

export default InstituteBlock;
