import { FC } from "react";
import { createPortal } from "react-dom";
import Lottie from "lottie-react";

import style from "./ScoreModal.module.scss";

import waitingTimeImage from "../../../../assets/json/ranking.json";

const ScoreModal: FC = () => {
  return createPortal(
    <div className={style.wrapper}>
      <div className={style.content}>
        <Lottie
          className={style.waitingImage}
          animationData={waitingTimeImage}
          loop={true}
          reversed
        />
        <p className={style.text}>Calculating Score</p>
        <p className={style.smallText}>Hang tight 😊</p>
      </div>
    </div>,
    document.body.querySelector("#rootContainer")
  );
};

export default ScoreModal;
