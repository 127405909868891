import { FC, ReactNode } from "react";

import classNames from "../../hooks/classNames";

import style from "./SideTabWrapper.module.scss";

import closeIcon from "../../assets/Icons/left.svg";

interface SideTabWrapperProps {
  children: ReactNode;
  title: string;
  closeModal: () => void;
  contentClassName?: string;
  titleClassName?: string;
  containerClassName?: string;
  headerClassName?: string;
  closeButtonClassName?: string;
  Actions?: FC<any>;
}

const SideTabWrapper: FC<SideTabWrapperProps> = ({
  children,
  title,
  closeModal,
  contentClassName,
  containerClassName,
  headerClassName,
  titleClassName,
  closeButtonClassName,
  Actions,
}) => {
  return (
    <div className={classNames(style.container, containerClassName)}>
      <div className={classNames(style.header, headerClassName)}>
        <button onClick={closeModal} className={classNames(style.closeButton, closeButtonClassName)}>
          <img src={closeIcon} alt="Close" />
        </button>
        <p className={classNames(style.title, titleClassName)}>{title}</p>
        {Actions && <Actions />}
      </div>
      <div className={classNames(style.content, contentClassName)}>
        {children}
      </div>
    </div>
  );
};

export default SideTabWrapper;
