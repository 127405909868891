import { FC } from "react";

import style from "./AppLogo.module.scss";

import logoIcon from "../../../assets/Logos/blackLogo.svg";
import classNames from "../../../hooks/classNames";

interface AppLogoProps {
  navigateToHome: () => void;
  sidebarIsOpen: boolean;
}

const AppLogo: FC<AppLogoProps> = ({ navigateToHome, sidebarIsOpen }) => {
  return (
    <div
      className={classNames(style.logoContainer, sidebarIsOpen && style.opened)}
      onClick={navigateToHome}
    >
      <img src={logoIcon} width={26} height={26} alt="School Hack" />
      <p>
        Powered By <span>School Hack</span>
      </p>
    </div>
  );
};

export default AppLogo;
