import { handleApiError } from "../helpers/string";
import api from "./api";
import { SubmitCryptoTransactionParams } from "../types";

export const CRYPTO_API = {
  async exchangeGrantKey(text: string) {
    try {
      const { data } = await api.post<{ access_token: string }>(
        "/user/auth/authtoken",
        { grant_key: text }
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async getCryptoNonce(
    transacrion_hash: string,
    network_name: string,
    signature: string
  ) {
    try {
      const { data } = await api.post(
        "/user/api/account/user/crypto/transaction",
        {
          body: { transacrion_hash, network_name, signature },
        }
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async submitCryptoTransaction({
    transactions_hash,
    network_name,
    signature,
    nonce,
    address,
  }: SubmitCryptoTransactionParams) {
    try {
      const { data } = await api.post(
        "/user/api/account/user/crypto/transaction",
        { transactions_hash, network_name, signature, nonce, address }
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async generateGrantAuthToken() {
    try {
      const { data } = await api.get("/user/api/account/generate_auth_token");
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async displayPriceOfAToken(token_address: string, network_name: string) {
    try {
      const { data } = await api.get(`/user/api/account/token/price`, {
        params: { token_address, network_name },
      });
      return data.live_token_price_in_usd;
    } catch (error) {
      throw new Error(error);
    }
  },
  async preAuthNonce(user_id: string) {
    try {
      const { data } = await api.get(`/user/auth/pre_auth/nonce`, {
        params: { user_id },
      });
      return data.nonce;
    } catch (error) {
      handleApiError(error);
    }
  },
  async getConfiguration() {
    try {
      const { data } = await api.get(`/user/api/account/configuration`, {
        params: { keyname: "crypto_tokens" },
      });
      return data.value;
    } catch (error) {
      handleApiError(error);
    }
  },
};
