import { Dispatch, FC, SetStateAction, useState } from "react";
import { createPortal } from "react-dom";

import { USER_API } from "../../../api/requests";
import useEscapeKey from "../../../hooks/useEscape";
import Button from "../../Button/Button";

import style from "./SendCredits.module.scss";

import closeIcon from "../../../assets/Icons/close.svg";
import emailIcon from "../../../assets/Icons/enterEmailIcon.svg";
import loadingIcon from "../../../assets/Icons/loader.svg";
import schoolHackTokenIcon from "../../../assets/New/Icons/schoolHackTokenIcon.svg";

interface SendCreditsProps {
  setSendCreditsModalOpened: Dispatch<SetStateAction<boolean>>;
  points: number;
}

const SendCredits: FC<SendCreditsProps> = ({
  setSendCreditsModalOpened,
  points = 0,
}) => {
  const [userName, setUserName] = useState("");
  const [isContinueClicked, setIsContinueClicked] = useState(false);
  const [coinsToSend, setCoinsToSend] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEscapeKey(closeModal);

  function closeModal() {
    setSendCreditsModalOpened(false);
  }

  async function sendCreditsToUser() {
    setIsLoading(true);

    const data = await USER_API.sendCredits(coinsToSend, userName.trim());
    if (data) closeModal();

    setIsLoading(false);
  }

  const updateCoinsAmount = (coinsString: string) => {
    const trimmedString = coinsString.replace(/^0+/, "");
    if (trimmedString.length > 1) {
      setCoinsToSend(Number(trimmedString));

      return;
    }
    setCoinsToSend(Number(coinsString));
  };

  const coinsCountText =
    coinsToSend > 1 || coinsToSend === 0
      ? `${coinsToSend} Points`
      : `${coinsToSend} Point`;

  return createPortal(
    <div className={style.backdrop}>
      <div className={style.modal}>
        {isLoading ? (
          <img
            src={loadingIcon}
            alt="Loading..."
            className={style.loadingImg}
          />
        ) : (
          <>
            <button className={style.closeBtn} onClick={closeModal}>
              <img src={closeIcon} alt="Close" />
            </button>
            <div className={style.coinsWrapper}>
              <img src={schoolHackTokenIcon} alt="Coin" width="50px" />
              <h1>
                {points} point{points !== 1 && "s"}
              </h1>
              <p>Available</p>
            </div>
            <h1 className={style.title}>Send Credits to Friends</h1>
            <p className={style.subTitle}>
              Enter the username of the recipient to send credits.
            </p>
            <div className={style.inputUserName}>
              <img src={emailIcon} alt="Email" width="16px" />
              <input
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Enter the username"
              />
            </div>
            {!isContinueClicked && (
              <Button
                variant="secondary"
                size="large"
                className={style.button}
                disabled={!userName}
                onClick={() => {
                  if (userName) setIsContinueClicked(true);
                }}
              >
                Continue
              </Button>
            )}
            {isContinueClicked && (
              <div className={style.checkSumWrapper}>
                <p>How many credits would you like to send?</p>
                <input
                  type="number"
                  value={coinsToSend.toString()}
                  onChange={(e) => updateCoinsAmount(e.target.value)}
                  className={style.coinsToSend}
                />
                <div className={style.pointsScoreWrapper}>
                  <img src={schoolHackTokenIcon} alt="Coin" width="16px" />
                  <p>{coinsCountText}</p>
                </div>
                <Button
                  variant="secondary"
                  size="large"
                  className={style.button}
                  disabled={
                    !userName || coinsToSend <= 0 || coinsToSend > points
                  }
                  onClick={sendCreditsToUser}
                >
                  Send
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>,
    document.body.querySelector("#rootContainer"),
  );
};

export default SendCredits;
