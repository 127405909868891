import { lazy } from "react";
import { Route } from "react-router-dom";

import MainLayout from "../Layouts/MainLayout/MainLayout";
import AuthPage from "../Pages/AuthPage/AuthPage";
import Crypto from "../Pages/Crypto/Crypto";
import AuthLayout from "./../Layouts/AuthLayout/AuthLayout";

import schoolHackTokenIconIcon from "../assets/New/Icons/schoolHackTokenIcon.svg";
import AIRecorderIcon from "../assets/New/Images/aiRecorder.png";
import AskAndyIcon from "../assets/New/Images/askAndy.png";
import DirectIcon from "../assets/New/Images/direct.png";
import EmailUsIcon from "../assets/New/Images/emailUs.png";
import EssayIcon from "../assets/New/Images/essay.png";
import FeedsIcon from "../assets/New/Images/feeds.png";
import GroupsIcon from "../assets/New/Images/groups.png";
import GuideIcon from "../assets/New/Images/guide.png";
import HistoryIcon from "../assets/New/Images/history.png";
import ParaphraseIcon from "../assets/New/Images/paraphrase.png";
import CheckPlagiarismIcon from "../assets/New/Images/plagiarism.png";
import QuizIcon from "../assets/New/Images/quiz.png";
import SmartDocsIcon from "../assets/New/Images/smartDocs.png";

const Profile = lazy(() => import("../Pages/UserProfile/Profile"));
const Home = lazy(() => import("../Pages/Home/Home"));
const EarnCredits = lazy(() => import("../Pages/EarnCredits/EarnCredits"));
const Essay = lazy(() => import("../Pages/Essay/Essay"));
const Knowledge = lazy(() => import("../Pages/Knowledge/Knowledge"));
const ModalAiRecorder = lazy(
  () => import("../Pages/Knowledge/ModalAiRecorder/ModalAiRecorder"),
);
const LeaderBoard = lazy(() => import("../Pages/LeaderBoard/LeaderBoard"));
const ChatHistory = lazy(() => import("../Pages/ChatHistory/ChatHistory"));
const Paraphrase = lazy(() => import("../Pages/Paraphrase/Paraphrase"));
const CheckPlagiarism = lazy(
  () => import("../Pages/CheckPlagiarism/CheckPlagiarism"),
);
const EmailUs = lazy(() => import("../Pages/EmailUs/EmailUs"));
const Feed = lazy(() => import("../Pages/Feed/Feed"));
const Group = lazy(() => import("../Pages/Groups/Group"));
const Game = lazy(() => import("../Pages/Game/Game"));
const CreateGameRoom = lazy(
  () => import("../Pages/Game/GameRoom/CreateGameRoom/CreateGameRoom"),
);
const PlayerGameRoom = lazy(
  () => import("../Pages/Game/GameRoom/PlayerGameRoom/PlayerGameRoom"),
);
const GameQuiz = lazy(() => import("../Pages/Game/GameRoom/GameQuiz/GameQuiz"));
const GameLeaderBoard = lazy(
  () => import("../Pages/Game/GameRoom/LeaderBoard/LeaderBoard"),
);
const GameRoom = lazy(() => import("../Pages/Game/GameRoom/GameRoom"));
const ResultsHistory = lazy(
  () => import("../Pages/Game/GameRoom/ResultsHistory/ResultsHistory"),
);
const GuideModel = lazy(() => import("../Pages/Guide/Guide"));
const Direct = lazy(() => import("../Pages/Direct/Direct"));

interface Route {
  path: string;
  element: JSX.Element;
  title?: string;
  routes?: Record<string, Route>;
  hideLinked?: boolean;
  icon?: { src: string; width: number; height: number };
  newPage?: boolean;
  disable?: boolean;
}

export interface RouteCategory {
  categoryTitle: string;
  routes: Route[];
}

const routes: Record<string, Route> = {
  auth: {
    path: "/auth",
    element: <AuthLayout />,
    title: "Authentication",
    routes: {
      login: {
        path: "/login",
        element: <AuthPage />,
        title: "Login",
      },
      register: {
        path: "/register",
        element: <AuthPage pageVariant={"register"} />,
        title: "Register",
      },
    },
  },
  crypto: {
    path: "testpage",
    element: <Crypto />,
  },
  main: {
    path: "/",
    element: <MainLayout />,
    title: "Insert title",
    routes: {
      feed: {
        path: "feed",
        element: <Feed />,
        title: "Feeds",
        icon: { src: FeedsIcon, width: 30, height: 27 },
        newPage: true,
      },
      groups: {
        path: "group",
        element: <Group />,
        title: "Groups",
        icon: { src: GroupsIcon, width: 29, height: 34 },
        newPage: true,
      },
      directMessages: {
        path: "direct",
        element: <Direct />,
        title: "Direct Messages",
        icon: { src: DirectIcon, width: 28, height: 27 },
        newPage: true,
      },
      askAndy: {
        path: "",
        element: <Home />,
        title: "Ask Andy",
        icon: { src: AskAndyIcon, width: 26, height: 27 },
      },
      knowledge: {
        path: "knowledge",
        element: <Knowledge />,
        title: "Knowledge",
        icon: { src: SmartDocsIcon, width: 26, height: 27 },
        newPage: true,
      },
      aiRecorder: {
        path: "knowledge?action=ai-recorder",
        element: <ModalAiRecorder countRange={() => {}} />,
        title: "AI Recorder",
        icon: { src: AIRecorderIcon, width: 17, height: 31 },
      },
      // smartMedia: {
      //   path: "smart-media",
      //   element: <SmartMedia />,
      //   title: "Smart Media",
      //   icon: { src: SmartMediaIcon, width: 26, height: 24 },
      //   newPage: true,
      // },
      earnCredits: {
        path: "earn-credits",
        element: <EarnCredits />,
        title: "Earn Free Credits",
        icon: { src: schoolHackTokenIconIcon, width: 26, height: 26 },
        newPage: true,
        hideLinked: true,
      },
      leaderBoard: {
        path: "leader-board",
        element: <LeaderBoard />,
        title: "Profile",
        disable: true,
      },
      history: {
        path: "history",
        element: <ChatHistory />,
        title: "History",
        icon: { src: HistoryIcon, width: 26, height: 26 },
      },
      paraphrase: {
        path: "paraphrase",
        element: <Paraphrase />,
        title: "Paraphrase",
        icon: { src: ParaphraseIcon, width: 24, height: 27 },
        newPage: true,
      },
      checkPlagiarism: {
        path: "check-plagiarism",
        element: <CheckPlagiarism />,
        title: "Check plagiarism",
        icon: { src: CheckPlagiarismIcon, width: 26, height: 30 },
        newPage: true,
      },
      essay: {
        path: "essay",
        element: <Essay />,
        title: "Essay",
        icon: { src: EssayIcon, width: 26, height: 19 },
        newPage: true,
      },
      emailUs: {
        path: "email-us",
        element: <EmailUs />,
        title: "Email Us",
        icon: { src: EmailUsIcon, width: 26, height: 29 },
        disable: true,
      },
      guide: {
        path: "guide",
        element: <GuideModel />,
        title: "Guide",
        icon: { src: GuideIcon, width: 26, height: 26 },
      },
      profile: {
        path: "profile",
        element: <Profile />,
        title: "Profile",
        disable: true,
      },
      game: {
        path: "game",
        element: <Game />,
        title: "Quiz",
        icon: { src: QuizIcon, width: 25, height: 22 },
        routes: {
          multiplayer: {
            path: "/multi",
            element: <Game quizMode="multiPlayer" />,
            routes: {
              create: {
                path: "/create",
                element: <CreateGameRoom />,
              },
              results: {
                path: "/results",
                element: <GameLeaderBoard />,
              },
              room: {
                path: "/room",
                element: <PlayerGameRoom />,
              },
              quiz: {
                path: "/quiz",
                element: <GameQuiz />,
              },
              settings: {
                path: "/settings",
                element: <CreateGameRoom />,
              },
              history: {
                path: "/history",
                element: <GameRoom isHistoryPage />,
                routes: {
                  results: {
                    path: "/results",
                    element: <ResultsHistory />,
                    routes: {
                      game: {
                        path: "/game",
                        element: <GameLeaderBoard isHistoryPage />,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

type RouteList = JSX.Element[];

function generateRouteComponents(
  routes: Record<string, Route>,
  parentPath?: string,
): RouteList {
  const routeList: RouteList = [];

  Object.keys(routes).forEach((routeName) => {
    const route = routes[routeName];
    const path = parentPath ? parentPath + route.path : route.path;

    if (route.path === "/" || route.path === "/auth") {
      routeList.push(
        <Route key={path} path={path} element={route.element}>
          {generateRouteComponents(route.routes, path)}
        </Route>,
      );
      return;
    }

    routeList.push(<Route key={path} path={path} element={route.element} />);

    if (route.routes) {
      routeList.push(...generateRouteComponents(route.routes, path));
    }
  });

  return routeList;
}

const filterRoutes = (routes: Route[], isLinked: boolean) => {
  if (!isLinked) return routes;

  return routes.filter((route) => !route.hideLinked);
};

const sidebarRoutes = (isLinked: boolean): Record<string, RouteCategory> => ({
  feedsGroups: {
    categoryTitle: "Feeds & Groups",
    routes: [
      routes.main.routes.feed,
      routes.main.routes.groups,
      routes.main.routes.directMessages,
    ],
  },
  smartTools: {
    categoryTitle: "Smart Tools",
    routes: filterRoutes(
      [
        routes.main.routes.askAndy,
        routes.main.routes.knowledge,
        // routes.main.routes.smartMedia,
        routes.main.routes.aiRecorder,
        routes.main.routes.earnCredits,
        routes.main.routes.history,
        routes.main.routes.game,
      ],
      isLinked,
    ),
  },
  moreTools: {
    categoryTitle: "More Tools",
    routes: [
      routes.main.routes.paraphrase,
      routes.main.routes.checkPlagiarism,
      routes.main.routes.essay,
    ],
  },
  support: {
    categoryTitle: "Support",
    routes: [routes.main.routes.emailUs, routes.main.routes.guide],
  },
});

const routeComponents = generateRouteComponents(routes);

export { routes, routeComponents, sidebarRoutes };
