import { FC } from "react";

import Modal from "../../../../Components/Modal";

import style from "./ExitGroupModal.module.scss";

interface ExitGroupModalProps {
  closeModal: () => void;
  groupId: string;
  removeGroup: (id: string) => void;
}

const ExitGroupModal: FC<ExitGroupModalProps> = ({
  closeModal,
  groupId,
  removeGroup,
}) => {
  return (
    <Modal closeModal={closeModal} styles={style.modal}>
      <div className={style.modalContainer}>
        <h3 className={style.modalHeading}>
          Are you sure you want to Exit the group?
        </h3>
        <div className={style.buttonsContainer}>
          <button className={style.modalButton} onClick={() => closeModal()}>
            No
          </button>
          <button
            className={style.modalButton}
            onClick={() => removeGroup(groupId)}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ExitGroupModal;
