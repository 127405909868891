import { placeholder, AdvancedImage } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { format, quality } from "@cloudinary/url-gen/actions/delivery";
import { thumbnail } from "@cloudinary/url-gen/actions/resize";
import { ComponentPropsWithoutRef, memo, useState } from "react";

import classNames from "../../hooks/classNames";
import ZoomedPictureModal from "./ZoomedPictureModal";

import style from "./ZoomedPictureModal.module.scss";

const PUBLIC_ID_PREFIX = "shisheo-resources/schoolhack-documents-";
const DETECT_NEW_URL = "/shisheo/image/upload/";

interface ZoomedPictureProps extends ComponentPropsWithoutRef<"img"> {
  src: string;
  thumbnailWidth?: number;
  thumbnailHeight?: number;
  isKnowledge?: boolean;
  imageWrapperClassName?: string;
  backdropClassName?: string;
}

const ZoomedPicture = ({
  src,
  imageWrapperClassName,
  backdropClassName,
  thumbnailWidth = 90,
  thumbnailHeight = 90,
  isKnowledge = false,
  ...props
}: ZoomedPictureProps) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const cld = new Cloudinary({
    cloud: {
      cloudName: "shisheo",
    },
  });

  const myImage = cld
    .image([PUBLIC_ID_PREFIX, src?.split(PUBLIC_ID_PREFIX)[1]].join(""))
    .resize(thumbnail().width(thumbnailWidth).height(thumbnailHeight))
    .delivery(format("auto"))
    .delivery(quality("auto"));

  return (
    <>
      <button
        onClick={() => setIsModalOpened(true)}
        className={style.imageButton}
      >
        {isKnowledge && src?.includes(DETECT_NEW_URL) ? (
          <AdvancedImage
            cldImg={myImage}
            plugins={[placeholder({ mode: "blur" })]}
            className={classNames(style.image, props.className)}
          />
        ) : (
          <img
            src={src}
            alt={props.alt}
            className={classNames(style.image, props.className)}
            {...props}
          />
        )}
      </button>

      <ZoomedPictureModal
        closeModal={() => setIsModalOpened(false)}
        image={src}
        imageWrapperClassName={imageWrapperClassName}
        backdropClassName={backdropClassName}
        isZoomedPictureOpen={isModalOpened}
      />
    </>
  );
};

export default memo(ZoomedPicture);
