import { FC } from "react";

import { selectTheme, setAppTheme } from "../../Slice/userSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";

import style from "./ThemeButton.module.scss";

import darkIcon from "../../assets/Icons/moon.svg";
import lightIcon from "../../assets/Icons/sun.svg";
import classNames from "../../hooks/classNames";

interface ThemeButtonProps {
  isSidebar?: boolean;
}

const ThemeButton: FC<ThemeButtonProps> = ({ isSidebar }) => {
  const dispatch = useAppDispatch();

  const { isLightTheme } = useAppSelector(selectTheme);

  const onClickSetTheme = () => {
    const newTheme = isLightTheme ? "dark" : "light";
    window.localStorage.setItem("theme", newTheme);
    dispatch(setAppTheme(newTheme));
  };

  return (
    <button
      className={classNames(style.themeBtn, isSidebar && style.sidebar)}
      onClick={onClickSetTheme}
    >
      <img src={isLightTheme ? darkIcon : lightIcon} alt="Current theme" />
      {isLightTheme && !isSidebar ? "Dark" : "Light"}
    </button>
  );
};

export default ThemeButton;
