import { Dispatch, FC, SetStateAction } from "react";

import classNames from "../../../../hooks/classNames";
import Toggle from "../../../../Components/Toggle";

import style from "./GroupMenu.module.scss";

import mediaIcon from "../../../../assets/Icons/media.svg";
import angleIcon from "../../../../assets/Icons/rightAngle.svg";
import starIcon from "../../../../assets/Icons/union.svg";
import clipIcon from "../../../../assets/Icons/clip.svg";
import settingsIcon from "../../../../assets/Icons/settings.svg";

interface GroupMenuProps {
  isAdmin: boolean;
  isMember: boolean;
  toggleInvite: boolean;
  toggleApproval: boolean;
  isPublicGroup: boolean;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  onChangeInvite: () => void;
  onChangeApproval: () => void;
  onPublicChange: () => void;
}

const GroupMenu: FC<GroupMenuProps> = ({
  isAdmin,
  isMember,
  toggleInvite,
  toggleApproval,
  isPublicGroup,
  setSelectedTab,
  onChangeInvite,
  onChangeApproval,
  onPublicChange,
}) => {
  return (
    <div className={style.tab}>
      {isMember && (
        <ul>
          <li
            className={style.link}
            onClick={() => setSelectedTab("mediaAndDocs")}
          >
            <div className="d-flex align-items-center">
              <img src={mediaIcon} alt="Media" />
              <p>Media and Docs</p>
            </div>
            <img src={angleIcon} alt="angle" />
          </li>
          <li
            className={style.link}
            onClick={() => setSelectedTab("starredMessages")}
          >
            <div className="d-flex align-items-center">
              <img src={starIcon} alt="star" />
              <p>Starred Messages</p>
            </div>
            <img src={angleIcon} alt="angle" />
          </li>
          <li className={style.link} onClick={() => setSelectedTab("invite")}>
            <div className="d-flex align-items-center">
              <img src={clipIcon} alt="clip" />
              <p>Invite Via Group Link</p>
            </div>
            <img src={angleIcon} alt="angle" />
          </li>
        </ul>
      )}
      {isAdmin && (
        <>
          <div
            className={classNames(style.link, style.setting)}
            onClick={() => setSelectedTab("edit")}
          >
            <div className="d-flex align-items-center">
              <img src={settingsIcon} alt="Settings" />
              <p>Edit Group</p>
            </div>
          </div>
          <div className={style.toggleList}>
            <div className={style.toggle}>
              <div>
                <h2>Anyone can invite</h2>
                <p>
                  Group members can invite anyone through email to join the
                  group.
                </p>
              </div>
              <Toggle
                className={style.toggleBg}
                checked={toggleInvite}
                onChange={onChangeInvite}
              />
            </div>

            <div className={style.toggle}>
              <div>
                <h2>Enable admin approval</h2>
                <p>
                  The members who are joining through invite link needs admin
                  approval.
                </p>
              </div>
              <Toggle
                className={style.toggleBg}
                checked={toggleApproval}
                onChange={onChangeApproval}
              />
            </div>

            <div className={style.toggle}>
              <div>
                <h2>Public Group</h2>
                <p>Makes this group public and discoverable.</p>
              </div>
              <Toggle
                className={style.toggleBg}
                checked={isPublicGroup}
                onChange={onPublicChange}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GroupMenu;
