import { useState, useEffect, useRef, FC } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

import { USER_API } from "../../api/requests";
import { COOKIE_TOKEN_NAME, setCookie } from "../../helpers/cookies";
import classNames from "../../hooks/classNames";
import Spinner from "../Spinner/Spinner";

import style from "./QrCode.module.scss";

import QRCodeIcon from "../../assets/Images/qrcode.png";
import scanIcon from "../../assets/Icons/scanIcon.svg";

const QrCode: FC = () => {
  const navigate = useNavigate();

  const [timeLeft, setTimeLeft] = useState(29);
  const [QRCodeIconLink, setQRCodeIconLink] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const intervalRef = useRef<ReturnType<typeof setInterval>>(null);

  useEffect(() => {
    if (!isActive) return;

    generateNewQRCode();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isActive]);

  async function generateNewQRCode() {
    setIsLoading(true);

    const result = await USER_API.generateQRToken();
    if (result) {
      setQRCodeIconLink(result.generated_qr_code);
      setQRCodeTimer(result.token);
    } else {
      clearInterval(intervalRef.current);
    }

    setIsLoading(false);
  }

  const setQRCodeTimer = (generatedToken: string) => {
    intervalRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime > 0) {
          verifyQRCode(generatedToken);
          return prevTime - 1;
        } else {
          setIsActive(false);
          setTimeLeft(29);
          return prevTime;
        }
      });
    }, 1000);
  };

  async function verifyQRCode(generatedToken: string) {
    const result = await USER_API.verifyQrCode(generatedToken);
    if (result && result.access_token) {
      toast.success("Successfully authorized");
      setCookie(COOKIE_TOKEN_NAME, result.access_token);
      navigate("/");
    }
  }

  return (
    <div className={style.rightSection}>
      <div className={classNames(style.QRcode, isActive && style.active)}>
        {!isActive && (
          <div className={style.overlay}>
            <button onClick={() => setIsActive(true)}>
              <img src={scanIcon} alt="Scan QR Code" />
              <p>Get QR Code</p>
            </button>
          </div>
        )}
        {isLoading ? (
          <Spinner variant="dark" />
        ) : (
          <img src={isActive ? QRCodeIconLink : QRCodeIcon} alt="QR code" />
        )}
        {isActive && (
          <p className={style.timeLeft}>
            00:{timeLeft < 10 ? <>0{timeLeft}</> : <>{timeLeft}</>}
          </p>
        )}
      </div>

      <p className={style.title}>Scan QR code</p>
      <p className={style.description}>
        Link your account by scanning this QR using the School Hack mobile app
      </p>
    </div>
  );
};

export default QrCode;
