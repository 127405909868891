import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import classNames from "../../hooks/classNames";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import useMediaQuery from "../../hooks/useMediaQuery";
import { sidebarRoutes } from "../../Routes/routes";
import {
  fetchCompanyLink,
  fetchCompanyUnlink,
} from "../../Slice/companyLinkSlice";
import { fetchUserInfo, selectTheme } from "../../Slice/userSlice";
import { COMPANY_LINK } from "../../constants";
import { COOKIE_TOKEN_NAME, deleteCookie } from "../../helpers/cookies";
import {
  removeCompanyId,
  removePrimary,
  removeSecondary,
} from "../../helpers/localStorage";
import CreditsInfo from "../CreditsInfo/CreditsInfo";
import ThemeButton from "../ThemeButton/ThemeButton";
import AppLogo from "./AppLogo/AppLogo";
import SidebarActions from "./SidebarActions/SidebarActions";
import SidebarCategories from "./SidebarCategories/SidebarCategories";
import UserProfile from "./UserProfile/UserProfile";
import sidebar from "./index";

import style from "./Sidebar.module.scss";

interface SidebarProps {
  sidebarIsOpen: boolean;
  setSidebarIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar: FC<SidebarProps> = ({
  sidebarIsOpen,
  setSidebarIsOpen,
  setModalOpened,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sidebarRef = useRef(null);

  const isDesktop = useMediaQuery("(min-width: 991px)");

  const savedCompanyLink = localStorage.getItem(COMPANY_LINK);

  const [isAddedZIndex, setIsAddedZIndex] = useState(false);

  const { isLinked } = useAppSelector(selectTheme);

  const route = sidebarRoutes(isLinked);

  useEffect(() => {
    if (!sidebarIsOpen) {
      return;
    }

    window.addEventListener("click", handleOutsideSidebarClick);

    return () => {
      window.removeEventListener("click", handleOutsideSidebarClick);
    };
  }, [sidebarIsOpen]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (!sidebarIsOpen) {
      timeout = setTimeout(() => {
        setIsAddedZIndex(false);
      }, 400);
    } else {
      setIsAddedZIndex(true);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [sidebarIsOpen]);

  const handleOutsideSidebarClick = (e) => {
    if (e.target.closest("#toggleSidebar")) return;
    if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
      setSidebarIsOpen(false);
    }
  };

  const handleOnProfileImageClick = () => {
    navigate("/profile");
    setSidebarIsOpen(false);
  };

  const handleLogoutClick = () => {
    deleteCookie(COOKIE_TOKEN_NAME);
    localStorage.removeItem("company-link");
    localStorage.removeItem("companyId");
    localStorage.removeItem("companySecondaryColor");
    localStorage.removeItem("companyPrimaryColor");
    window.location.reload();
  };

  const handleUnlinkClick = async () => {
    await dispatch(fetchCompanyUnlink());
    removePrimary();
    removeSecondary();
    removeCompanyId();
    window.location.reload();
  };

  const handleRelinkClick = () => {
    dispatch(fetchCompanyLink(savedCompanyLink));
    setModalOpened(true);
    dispatch(fetchUserInfo({ navigate }));
  };

  return (
    <header
      className={classNames(
        "sidebar",
        style.sidebar,
        sidebarIsOpen && style.opened,
        !isDesktop && style.mobile,
        isAddedZIndex && style.addZIndex,
      )}
      ref={sidebarRef}
    >
      <div
        className={style.boxNav}
        onMouseOver={() => setSidebarIsOpen(true)}
        onMouseOut={() => setSidebarIsOpen(false)}
      >
        <div className={style.mobileContainer}>
          <div className={style.mobileButtonContainer}>
            <SidebarActions
              handleLogoutClick={handleLogoutClick}
              handleRelinkClick={handleRelinkClick}
              handleUnlinkClick={handleUnlinkClick}
              savedCompanyLink={savedCompanyLink}
            />
            <div className={style.leftSideButtons}>
              <CreditsInfo />
            </div>
            {!isLinked && <ThemeButton />}
          </div>

          <UserProfile
            setSidebarIsOpen={setSidebarIsOpen}
            handleOnProfileImageClick={handleOnProfileImageClick}
          />
        </div>

        <AppLogo
          navigateToHome={() => navigate("/")}
          sidebarIsOpen={sidebarIsOpen}
        />

        <SidebarCategories
          routeList={route}
          setSidebarIsOpen={setSidebarIsOpen}
          sidebarIsOpen={sidebarIsOpen}
        />
      </div>
    </header>
  );
};

export default Sidebar;
