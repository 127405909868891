import ContentLoader from "react-content-loader";

const SettingsGroupDescriptionSkeleton = ({
  isLight,
}: {
  isLight?: boolean;
}) => {
  return (
    <ContentLoader
      speed={2}
      width="306"
      height={20}
      viewBox="0 0 320 20"
      backgroundColor={isLight ? "#d6d6d6" : "#383838"}
      foregroundColor={isLight ? "#bababa" : "#666666"}
    >
      <rect x="0" y="0" rx="3" ry="3" width="140" height="14" />
    </ContentLoader>
  );
};

export default SettingsGroupDescriptionSkeleton;
