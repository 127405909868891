import { useState } from "react";

import classNames from "../../hooks/classNames";
import { useAppSelector } from "../../hooks/redux-hooks";
import { selectTheme } from "../../Slice/userSlice";
import SendCredits from "./SendCredits";

import style from "./CreditsInfo.module.scss";

import schoolHackTokenIcon from "../../assets/New/Icons/schoolHackTokenIcon.svg";

interface CreditsInfoProps {
  className?: string;
}

const CreditsInfo = ({ className }: CreditsInfoProps) => {
  const [isSendCreditsModalOpened, setIsSendCreditsModalOpened] =
    useState(false);

  const { isLinked } = useAppSelector(selectTheme);
  const credits = useAppSelector((state) => state.user.info?.credits);
  const roundedCredits = parseFloat(credits?.toFixed(2));

  const handleCreditsContainerClick = () => {
    if (!isLinked) {
      setIsSendCreditsModalOpened(true);
    }
  };

  const creditsCountText =
    (roundedCredits || 0) > 1 || (roundedCredits || 0) === 0
      ? `${roundedCredits || 0} points`
      : `${roundedCredits || 0} point`;

  if (isLinked) {
    return;
  }

  return (
    <>
      <div
        onClick={handleCreditsContainerClick}
        className={classNames(style.container, className)}
      >
        <div className={style.coinImage}>
          <img src={schoolHackTokenIcon} alt="Coin" width={42} height={42} />
        </div>
        <div className={style.creditsCount}>
          <p>{creditsCountText}</p>
        </div>
      </div>

      {isSendCreditsModalOpened && !isLinked && (
        <SendCredits
          setSendCreditsModalOpened={setIsSendCreditsModalOpened}
          points={roundedCredits || 0}
        />
      )}
    </>
  );
};

export default CreditsInfo;
