import React, { ComponentPropsWithoutRef } from 'react';
import classNames from '../../hooks/classNames';
import style from "./Button.module.scss";

/* primary - dark button
secondary - light button
primaryOutlined - dark button with outline
secondaryOutlined - light button with outline
outlined - transparent button with outline */

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
 className?: string;
 variant?: 'primary' | "secondary" | "primaryOutlined" | "secondaryOutlined" | 'outlined';
 size?: "small" | "medium" | "large",
 full?: boolean // width: 100%;
}

const Button: React.FC<ButtonProps> = ({
 className,
 variant = 'primary',
 size = "medium",
 full = false,
 ...props
}) => (<button className={classNames(style.button, style[size], style[variant], full ? style.full : "", className)} {...props} />);

export default Button;