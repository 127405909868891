import { format } from "date-fns";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useSearchParams } from "react-router-dom";

import SideTabWrapper from "../../../Components/SideTabWrapper/SideTabWrapper";
import ZoomedPicture from "../../../Components/ZoomedPicture/ZoomedPicture";
import classNames from "../../../hooks/classNames";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import {
  ExitGroup,
  fetchGroupMembers,
  fetchMoreGroupMembers,
} from "../../../Slice/groupSlice";
import { GROUPS_API } from "../../../api/requests";
import GroupSidebarSkeleton from "../GroupSidebar/GroupSidebarSkeleton";
import ExitGroupModal from "./ExitGroupModal/ExitGroupModal";
import GroupMenu from "./GroupMenu/GroupMenu";
import MemberItem from "./MemberItem/MemberItem";
import SettingsGroupDescriptionSkeleton from "./Skeletons/SettingsGroupDescriptionSkeleton";
import SettingsGroupImageSkeleton from "./Skeletons/SettingsGroupImageSkeleton";

import { GroupDetailsType, SelectedTabType } from "../../../types";

import style from "./SettingsGroup.module.scss";

import editIcon from "../../../assets/Icons/edit.svg";
import plusIcon from "../../../assets/Icons/plusWidthBg.svg";
import profileLogoIcon from "../../../assets/Icons/profileLogo.jpg";

export const MEMBERS_LIMIT = 10;

interface SettingsGroupProps {
  openProfile: (id: string) => void;
  closeTab: () => void;
  setSelectedTab: Dispatch<SetStateAction<SelectedTabType>>;
}

const SettingsGroup: FC<SettingsGroupProps> = ({
  openProfile,
  setSelectedTab,
  closeTab,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [params, setSearchParams] = useSearchParams();
  const groupId = params.get("groupId");
  const isMyGroup = !params.get("public");

  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [toggleInvite, setToggleInvite] = useState(false);
  const [toggleApproval, setToggleApproval] = useState(false);
  const [isPublicGroup, setIsPublicGroup] = useState(false);
  const [groupDetails, setGroupDetails] = useState<GroupDetailsType>(null);

  const userId = useAppSelector((state) => state.user.info.user_id);
  const members = useAppSelector((state) => state.group.members);
  const hasMoreGroupMembers = useAppSelector(
    (state) => state.group.hasMoreGroupMembers,
  );

  const isAdmin = groupDetails?.user_id === userId;
  const showInvite = (isAdmin && !toggleInvite) || toggleInvite;
  const isMember =
    location?.state?.isMember ||
    members.find((el) => el.user_id === userId) ||
    isMyGroup;

  useEffect(() => {
    if (!groupId) return;
    getGroupDetails();
    dispatch(fetchGroupMembers(groupId, MEMBERS_LIMIT));
  }, [groupId]);

  const getGroupDetails = async () => {
    const currentGroup = await GROUPS_API.getGroupDetails(groupId);

    setToggleInvite(currentGroup?.settings.allow_member_invite);
    setToggleApproval(currentGroup?.settings.enforce_join_approval);
    setIsPublicGroup(currentGroup?.settings.is_public);
    setGroupDetails(currentGroup);
  };

  const removeGroup = (id) => {
    dispatch(ExitGroup(id));
    setSearchParams("");
    setExitModalOpen(false);
    closeTab();
    params.delete("groupId");
    setSearchParams(params);
  };

  const updateGroupSettings = async (newSettings) => {
    const updatedGroup = {
      ...groupDetails,
      settings: {
        ...groupDetails?.settings,
        ...newSettings,
      },
    };

    await GROUPS_API.putUpdateGroup(groupId, updatedGroup);
  };

  const onChangeInvite = async () => {
    const newSettings = {
      allow_member_invite: !groupDetails?.settings.allow_member_invite,
    };
    setToggleInvite((prev) => !prev);

    await updateGroupSettings(newSettings);
  };

  const onChangeApproval = async () => {
    const newSettings = {
      enforce_join_approval: !groupDetails?.settings.enforce_join_approval,
    };

    setToggleApproval((prev) => !prev);

    await updateGroupSettings(newSettings);
  };

  const onPublicChange = async () => {
    const newSettings = {
      is_public: !groupDetails?.settings.is_public,
    };

    setIsPublicGroup((prev) => !prev);

    await updateGroupSettings(newSettings);
  };

  const handleOpenEditGroupBar = () => {
    if (isAdmin) {
      setSelectedTab("edit");
      return;
    }
  };

  const getCreatedGroupDate = (date) => {
    if (date) {
      return format(new Date(date), "dd LLLL yyyy");
    }
    return null;
  };

  const fetchMoreMembers = () => {
    dispatch(
      fetchMoreGroupMembers(
        groupId,
        MEMBERS_LIMIT,
        members[members.length - 1]._id,
      ),
    );
  };

  return (
    <>
      <SideTabWrapper
        title={isAdmin ? "Settings" : "Info"}
        closeModal={closeTab}
      >
        <div className={style.contentWrapper}>
          <div>
            <div className={style.mainProfile} onClick={handleOpenEditGroupBar}>
              {groupDetails ? (
                <ZoomedPicture
                  src={groupDetails?.image || profileLogoIcon}
                  alt="Group Image"
                  className={classNames(
                    style.profile,
                    isAdmin && style.cursorPointer,
                  )}
                />
              ) : (
                <SettingsGroupImageSkeleton />
              )}

              {isAdmin && (
                <div className={style.edit}>
                  <img src={editIcon} alt="Edit" />
                </div>
              )}
            </div>
            <div className={style.description}>
              <h3>Description</h3>
              <p>
                {groupDetails ? (
                  groupDetails?.description ||
                  "There is no description of the group yet"
                ) : (
                  <SettingsGroupDescriptionSkeleton />
                )}
              </p>
            </div>
            <GroupMenu
              isAdmin={isAdmin}
              isMember={isMember}
              toggleInvite={toggleInvite}
              toggleApproval={toggleApproval}
              isPublicGroup={isPublicGroup}
              setSelectedTab={setSelectedTab}
              onChangeInvite={onChangeInvite}
              onChangeApproval={onChangeApproval}
              onPublicChange={onPublicChange}
            />
            <div className={style.participants}>
              <h3>{`${groupDetails?.total_members || 0} Participant${
                groupDetails?.total_members === 1 ? "" : "s"
              }`}</h3>
              {showInvite && isMember && (
                <div
                  className={style.addParticipants}
                  onClick={() => setSelectedTab("addParticipants")}
                >
                  <div className="d-flex align-items-center">
                    <img src={plusIcon} alt="plus" />
                    <h3>Add more Participants </h3>
                  </div>
                </div>
              )}
            </div>
            <div>
              <InfiniteScroll
                dataLength={members.length}
                height={members.length > 5 ? "260px" : "fit-content"}
                next={fetchMoreMembers}
                hasMore={hasMoreGroupMembers}
                className={style.membersList}
                loader={<GroupSidebarSkeleton />}
              >
                {members.length &&
                  members.map((member) => (
                    <MemberItem
                      key={member._id}
                      member={member}
                      openProfile={openProfile}
                    />
                  ))}
              </InfiniteScroll>
            </div>
          </div>

          <div>
            {isMember && (
              <div className={style.exit}>
                <button
                  className={style.exitBtn}
                  onClick={() => setExitModalOpen((prev) => !prev)}
                >
                  Exit Group
                </button>
              </div>
            )}
            <p className={style.created}>
              Created By {groupDetails?.user_meta?.first_name} |{" "}
              {getCreatedGroupDate(groupDetails?.created_at)}
            </p>
          </div>
        </div>
      </SideTabWrapper>
      {exitModalOpen && (
        <ExitGroupModal
          closeModal={() => setExitModalOpen(false)}
          groupId={groupId}
          removeGroup={removeGroup}
        />
      )}
    </>
  );
};

export default SettingsGroup;
