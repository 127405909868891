import { Dispatch, FC, SetStateAction } from "react";
import { Link, useLocation } from "react-router-dom";

import classNames from "../../../hooks/classNames";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { RouteCategory } from "../../../Routes/routes";
import { getActiveClassForSidebarLinks } from "../../../helpers/string";

import style from "./SidebarCategories.module.scss";

interface SidebarCategoriesProps {
  routeList: Record<string, RouteCategory>;
  setSidebarIsOpen: Dispatch<SetStateAction<boolean>>;
  sidebarIsOpen: boolean;
}

const SidebarCategories: FC<SidebarCategoriesProps> = ({
  routeList,
  setSidebarIsOpen,
  sidebarIsOpen,
}) => {
  const location = useLocation();
  const hasMobileContainer = useMediaQuery("(max-width: 990px)");

  return (
    <nav>
      {Object.keys(routeList).map((category, i) => {
        return (
          <div
            key={i}
            className={classNames(
              style.categoryBox,
              sidebarIsOpen && style.opened,
            )}
          >
            <p
              className={classNames(
                style.labelCategory,
                sidebarIsOpen && style.labelCategoryOpen,
              )}
            >
              {routeList[category].categoryTitle}
            </p>
            <ul className={style.categoriesList}>
              {routeList[category].routes.map((route, i) => {
                return (
                  <li
                    key={i}
                    onClick={() => setSidebarIsOpen(!hasMobileContainer)}
                  >
                    <Link
                      to={`/${route.path}`}
                      className={classNames(
                        style.listItemLink,
                        getActiveClassForSidebarLinks({
                          routeBasePath: `/${route.path}`,
                          pathname: location.pathname,
                          search: location.search,
                          styleActive: style.active,
                        }),
                      )}
                    >
                      <div className={style.iconWrapper}>
                        <img
                          src={route.icon.src}
                          alt={route.title}
                          width={route.icon.width}
                          height={route.icon.height}
                        />
                      </div>
                      {route.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </nav>
  );
};

export default SidebarCategories;
