import SkeletonWrapper from "../../../Components/SkeletonComponent";

const GroupSidebarSkeleton = () => {
  return (
    <SkeletonWrapper width="100%" height="100%">
      <rect x="60" y="15" rx="3" ry="3" width="60" height="14" />
      <rect x="60" y="35" rx="3" ry="3" width="80" height="14" />
      <rect
        x="calc(100% - 60px)"
        y="40%"
        rx="3"
        ry="3"
        width="60"
        height="14"
      />
      <circle cx="22" cy="33" r="22" />
    </SkeletonWrapper>
  );
};

export default GroupSidebarSkeleton;
