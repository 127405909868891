import axios from "axios";
import { toast } from "react-toastify";

import { MULTIMODAL_HISTORY_LIMIT } from "../constants";
import { handleApiError } from "../helpers/string";
import { api } from "./api";

import {
  ChatDocumentHistory,
  ChatHistoryType,
  ChatMessage,
  CreateGameResponse,
  DocumentType,
  ExtraMetaType,
  FeedbackType,
  FetchSearchPublicGroupsParams,
  GameMemberType,
  GameResults,
  GameRoomType,
  GameSettingsBody,
  GameSubmissionBody,
  GetCreditStats,
  GetSessionIdResponse,
  GetStats,
  GroupDetailsType,
  IChatHistory,
  ImageType,
  MediaType,
  MessageType,
  PostType,
  ProfileType,
  PublicGroup,
  QuizHistory,
  ReferralData,
  Room,
  RoomMessage,
  UpdateProfileBody,
  getLobbyMessagesProps,
  getMembersProps,
  getQuestionsResponse,
  getQuizSessionsParams,
  submitQuizSessionBody,
  GroupType,
} from "../types";

export const COMMENTS_LIMIT = 15;

export const SMART_FILES_API = {
  async getMediaDetails(id: string) {
    try {
      const { data } = await api.get<MediaType[]>(
        "/media/api/account/audio?sort=-createdAt",
        { params: { _id: id } },
      );
      return data?.[0];
    } catch (error) {
      handleApiError(error);
    }
  },
  async getMedia(page: number, limit: number, is_audio = true) {
    try {
      const { data } = await api.get<MediaType[]>("/media/api/account/audio", {
        params: { page, limit, sort: "-createdAt", is_audio },
      });
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async putSummarization(id: string) {
    try {
      await api.put(`/media/api/account/audio/execute/${id}`, {
        type: "summarization",
      });
    } catch (error) {
      handleApiError(error);
    }
  },
  async getDocuments(page: number, limit: number) {
    try {
      const { data } = await api.get<DocumentType[]>(
        "/media/api/account/document",
        {
          params: { page, limit, sort: "-createdAt" },
        },
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async getDocDetails(id: string) {
    try {
      const { data } = await api.get<DocumentType[]>(
        "/media/api/account/document",
        { params: { _id: id, sort: "-createdAt" } },
      );
      return data?.[0];
    } catch (error) {
      handleApiError(error);
    }
  },
  async putDocumentType(id: string, type: string) {
    try {
      await api.put(`/media/api/account/document/execute/${id}`, {
        type,
      });
    } catch (error) {
      handleApiError(error);
    }
  },
  async getChatHistory({ page, limit }: ChatHistoryType) {
    try {
      const { data } = await api.get<IChatHistory[]>(
        "/media/api/account/document/chat/history",
        { params: { page, limit, sort: "-createdAt" } },
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async getSessionId() {
    try {
      const { data } = await api.get<GetSessionIdResponse>(
        "/media/api/account/generate/session_id",
      );
      return data?.session_id;
    } catch (error) {
      handleApiError(error);
    }
  },
  async postChatMessage(type: string, body: ChatMessage) {
    try {
      await api.post(`/media/api/account/${type}/chat`, body);
    } catch (error) {
      handleApiError(error);
    }
  },
  async getChatHistoryBySessionId({
    type,
    sessionId,
    sortByLatest = false,
  }: ChatDocumentHistory) {
    try {
      const { data } = await api.get(
        `/media/api/account/${type}/chat/history`,
        {
          params: { session_id: sessionId, sort: sortByLatest && "-createdAt" },
        },
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async getDetails(id: string, type: string) {
    try {
      const { data } = await api.get<MediaType[] | DocumentType[]>(
        `/media/api/account/${type}?sort=-createdAt`,
        { params: { _id: id } },
      );
      return data?.[0];
    } catch (error) {
      handleApiError(error);
    }
  },
  async postRecorded(body: {
    media_url: any;
    name: string;
    is_recorder: boolean;
  }) {
    try {
      await api.post("/media/api/account/audio", body);
    } catch (error) {
      handleApiError(error);
    }
  },
  async postCloudinaryUpload(formData: any) {
    try {
      const { data } = await axios.post(
        "https://api.cloudinary.com/v1_1/shisheo/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async getAllPromptChatHistory({
    page,
    limit,
    session_id,
    sortByLatest,
  }: ChatHistoryType) {
    try {
      const { data } = await api.get("/user/api/account/prompt/chat/history", {
        params: {
          page,
          limit,
          sort: sortByLatest && "-createdAt",
          session_id,
        },
      });
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async deleteDocument(id: string, type: "audio" | "document") {
    try {
      const { data } = await api.delete(`/media/api/account/${type}/${id}`);

      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async postCreateDocument(body: { text: string }) {
    try {
      const { data } = await api.post(
        "/user/api/account/tools/text_to_docx",
        body,
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async generateAudio(text: string) {
    try {
      const { data } = await api.post(
        "/media/api/account/audio/generate/speech",
        { text },
        {
          responseType: "arraybuffer",
        },
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async generateTranscription(url: string) {
    try {
      const { data } = await api.post<{ transcript: string }>(
        "/media/api/account/audio/generate/transcription",
        { url },
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async createSuperDoc(body: {
    entity_ids: string[];
    type: "audio" | "document";
    name: string;
  }) {
    try {
      const { data } = await api.post(
        "/media/api/account/document/create/superdoc",
        body,
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async getImages(page: number = 1, limit: number = MULTIMODAL_HISTORY_LIMIT) {
    try {
      const { data } = await api.get<ImageType[]>("/media/api/account/image", {
        params: {
          limit,
          page,
          sort: "-createdAt",
        },
      });
      return data;
    } catch (err: unknown) {
      handleApiError(err);
    }
  },
};

export const USER_API = {
  async postLogin({ email, password }) {
    try {
      return await api.post("/user/auth/email/login", {
        email,
        password,
      });
    } catch (error) {
      throw error;
    }
  },
  async getReferral() {
    try {
      const { data } = await api.get<ReferralData>(
        "/user/api/account/referral",
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async getReferralLeaderboard() {
    try {
      const { data } = await api.get("/user/api/account/referral/leaderboard");
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async sendCredits(amount: number, receiver_username: string) {
    try {
      const { data } = await api.post("/user/api/account/credit/transfer", {
        amount,
        receiver_username,
      });
      toast.success(data.msg || "Transfer initialized");
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async putUpdateProfile(body: UpdateProfileBody) {
    try {
      const { data } = await api.put("/user/api/account/profile", body);
      toast.success("Profile info updated successfully");
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async putUpdateUsername(new_username: string) {
    try {
      const { data } = await api.put("/user/api/account/set_username", {
        new_username,
      });
      toast.success("Username updated successfully");
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async deleteProfile() {
    try {
      await api.delete("/user/api/account/profile");
    } catch (error) {
      handleApiError(error);
    }
  },
  async postCreatePostDocument(body: {
    entity_id: string;
    type: "audio" | "document";
    region_code: string;
    subject: string;
    title: string;
    description: string;
    educational_subject: string;
  }) {
    try {
      const { data } = await api.post("/media/api/account/post", body);
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async postCreatePostText(body: { description: string }) {
    try {
      const { data } = await api.post("/social/api/post/text", body);
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async postCreatePostImage(body: { description: string; media: string[] }) {
    try {
      const { data } = await api.post("/social/api/post/image", body);
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async getSubjects() {
    try {
      const { data } = await api.get(
        "/user/api/account/configuration?keyname=fields_of_study",
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async generateQRToken(): Promise<{
    generated_qr_code: string;
    token: string;
  }> {
    try {
      const { data } = await api.get("/user/auth/qr/generate_login_token");
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async verifyQrCode(login_token: string): Promise<{ access_token: string }> {
    try {
      const { data }: { data: { access_token: string } } = await api.post(
        "/user/auth/qr/verify_login_token",
        {
          login_token,
        },
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  },
  async getRelations(userId: string) {
    try {
      const { data } = await api.get(`/social/api/profile/${userId}/relations`);
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
};

export const DM_API = {
  async getRooms({
    page,
    limit,
    search,
    signal,
  }: {
    page?: number;
    limit?: number;
    search?: string;
    signal?: AbortSignal;
  }) {
    try {
      const { data } = await api.get<{ rooms: Room[] }>(
        `/social/api/dm/rooms`,
        {
          params: { page, limit, search },
          signal,
        },
      );
      return data.rooms;
    } catch (error) {
      if (signal?.aborted) {
        return;
      }
      handleApiError(error);
    }
  },
  async sendMessage(body: {
    receiver_id: string;
    type: string;
    body?: string;
    extra_meta?: ExtraMetaType;
    parent?: string;
  }): Promise<{
    room_message: RoomMessage;
  }> {
    try {
      const { data } = await api.post(`/social/api/dm/message`, body);
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async getMessages(
    limit: number,
    receiver_id: string,
    key?: string,
  ): Promise<{
    room: string;
    room_messages: RoomMessage[];
  }> {
    try {
      const { data } = await api.get(`/social/api/dm/messages`, {
        params: { receiver_id, limit, key },
      });
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async getProfile(userId: string) {
    try {
      const { data } = await api.get<ProfileType>(
        `/social/api/profile/${userId}`,
      );
      return data;
    } catch (error) {
      toast.error("Incorrect User ID!");
    }
  },
};
export const FEED_API = {
  async getGlobalFeed(page: number, limit: number) {
    try {
      const { data } = await api.get(`/social/api/post/feed/global`, {
        params: { page, limit },
      });
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async getSelectedPost(id: string) {
    try {
      const { data } = await api.get(`/social/api/post?_id=${id}`);
      return data[0];
    } catch (error) {
      handleApiError(error);
    }
  },
  async getPostComments(id: string, page: number) {
    try {
      const { data } = await api.get(`/social/api/post/${id}/comments/post`, {
        params: { page, limit: COMMENTS_LIMIT },
      });
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async getCommentsReplies(id: string, page: number) {
    try {
      const { data } = await api.get(
        `/social/api/post/${id}/comments/replies`,
        {
          params: { page, limit: COMMENTS_LIMIT },
        },
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async togglePostLike(id: string) {
    try {
      const { data } = await api.put(`/social/api/post/${id}/toggle_like`);
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async toggleCommentLike(id: string) {
    try {
      const { data } = await api.put(
        `/social/api/post/comment/${id}/toggle_like`,
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async deletePost(id: string) {
    try {
      await api.delete(`/social/api/post/${id}`);
      toast.success("Post deleted successfully");
    } catch (error) {
      handleApiError(error);
    }
  },
  async deleteComment(id: string) {
    try {
      const { data } = await api.delete(`/media/api/post/${id}/comment`);
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async postCreateComment(id: string, body) {
    try {
      await api.post(`/social/api/post/${id}/comment`, body);
    } catch (error) {
      handleApiError(error);
    }
  },
  async postCreateCommentReply(id: string, body) {
    try {
      await api.post(`/social/api/post/${id}/reply`, body);
    } catch (error) {
      handleApiError(error);
    }
  },
  async getUserPosts(
    userId: string,
    page: number,
    limit: number,
    type = "document",
  ) {
    try {
      const { data } = await api.get(`/social/api/post`, {
        params: {
          user_id: userId,
          type,
          limit,
          page,
          sort: "-createdAt",
        },
      });
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async getProfile(userId: string) {
    try {
      const { data } = await api.get<ProfileType>(
        `/social/api/profile/${userId}`,
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async getSearchPosts(
    input: string,
    page: number,
    limit: number,
    signal?: AbortSignal,
  ) {
    try {
      const { data } = await api.get<PostType[]>(`/social/api/post/search`, {
        params: {
          term: input,
          page,
          limit,
          fields: "preferred_search_field",
          type: "best_fields",
        },
        signal,
      });
      return data;
    } catch (error) {
      if (signal?.aborted) {
        return;
      }
      handleApiError(error);
    }
  },
  async postFollowUser(userId: string) {
    try {
      await api.post(`/social/api/profile/${userId}/follow_user`);
    } catch (error) {
      handleApiError(error);
    }
  },
  async deleteUnfollowUser(userId: string) {
    try {
      await api.delete(`/social/api/profile/${userId}/unfollow_user`);
    } catch (error) {
      handleApiError(error);
    }
  },
  async postBlockUser(userId: string) {
    try {
      await api.post(`/social/api/profile/${userId}/block`);
      toast.success("User blocked successfully");
    } catch (error) {
      handleApiError(error);
    }
  },
  async deleteBlockUser(userId: string) {
    try {
      await api.delete(`/social/api/profile/${userId}/unblock`);
      toast.success("User unblocked successfully");
    } catch (error) {
      handleApiError(error);
    }
  },
  async getSearchUserName(username: string, signal: AbortSignal) {
    try {
      const { data } = await api.get<ProfileType[]>(
        `/social/api/profile/search`,
        {
          params: { username },
          signal,
        },
      );
      return data;
    } catch (error) {
      if (signal?.aborted) {
        return;
      }
      handleApiError(error);
    }
  },
  async getSuggestedUsers() {
    try {
      const { data } = await api.get<ProfileType[]>(
        "/social/api/profile/suggested/popular",
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
};

export const QUIZ_API = {
  async getQuestions(id: string) {
    try {
      const { data } = await api.get<getQuestionsResponse>(
        `/media/api/account/quiz/questions/${id}`,
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async submitQuizSession(body: submitQuizSessionBody) {
    try {
      const { data } = await api.post(
        "/media/api/account/quiz/submissions",
        body,
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async getQuizSessions(params: getQuizSessionsParams) {
    try {
      const { data } = await api.get<QuizHistory[]>(
        "/media/api/account/quiz/submissions",
        {
          params,
        },
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
};

export const GROUPS_API = {
  async getMessages({
    groupId,
    limit,
    key,
    type,
  }: {
    groupId: string;
    limit: number;
    key?: string;
    type?: "document" | "media";
  }) {
    try {
      const { data } = await api.get<{ group_messages: MessageType[] }>(
        `/social/api/group/${groupId}/messages`,
        {
          params: {
            limit,
            type,
            key,
          },
        },
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async postSendMessage(
    groupId: string,
    body: {
      type: string;
      body?: string;
      extra_meta?: ExtraMetaType;
      parent?: string;
    },
  ) {
    try {
      const { data } = await api.post<{ group_message: MessageType }>(
        `/social/api/group/${groupId}/message`,
        body,
      );
      return data;
    } catch (error) {
      throw new Error(error?.response?.data?.message || "ERROR");
    }
  },

  async putStarMessage(groupId: string, messageId: string) {
    try {
      await api.put(`/social/api/group/${groupId}/message/${messageId}/star`);
      toast.success("Message successfully starred");
    } catch (error) {
      handleApiError(error);
    }
  },

  async putUnStarMessage(groupId: string, messageId: string) {
    try {
      await api.put(`/social/api/group/${groupId}/message/${messageId}/unstar`);
      toast.success("Message successfully unstarred");
    } catch (error) {
      handleApiError(error);
    }
  },

  async getStarredMessages(groupId: string, limit: number, key?: string) {
    try {
      const { data } = await api.get(
        `/social/api/group/${groupId}/starred_messages`,
        {
          params: {
            limit,
            key,
          },
        },
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async getJoinedGroups(
    page: number,
    limit: number,
    search?: string,
    signal?: AbortSignal,
  ) {
    try {
      const { data } = await api.get<{ joined_groups: GroupType[] }>(
        `/social/api/group/joined`,
        {
          params: {
            limit,
            page,
            search,
          },
          signal,
        },
      );
      return data;
    } catch (error) {
      if (signal?.aborted) {
        return;
      }
      handleApiError(error);
    }
  },

  async getMembers(groupId: string, limit: number) {
    try {
      const { data } = await api.get(`/social/api/group/${groupId}/members`, {
        params: {
          limit,
        },
      });
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async putUpdateGroup(groupId: string, body: GroupDetailsType) {
    try {
      await api.put(`/social/api/group/${groupId}`, body);
    } catch (error) {
      handleApiError(error);
    }
  },

  async getGroupsReqCount() {
    try {
      const { data } = await api.get(
        `/social/api/group/invites_and_requests_count`,
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async getGroupDetails(groupId: string) {
    try {
      const { data } = await api.get(`/social/api/group/${groupId}/info`);
      return data.group;
    } catch (error) {
      handleApiError(error);
    }
  },

  async updateGroup(groupId: string, body: {}) {
    try {
      const { data } = await api.put(`/social/api/group/${groupId}`, body);
      return data.group;
    } catch (error) {
      handleApiError(error);
    }
  },

  async getGroupByInvite(inviteCode: string) {
    try {
      const { data } = await api.get(`social/api/group/info/${inviteCode}`);
      return data.group;
    } catch (error) {
      throw new Error(error?.response?.data?.message || "ERROR");
    }
  },

  async sendJoinGroupRequest(groupId: string) {
    try {
      const { data } = await api.post(
        `/social/api/group/${groupId}/join_request`,
      );

      if (data.group_join_request.is_joined) {
        toast.success("You have successfully joined the group");
      } else {
        toast.success("Request successfully sent");
      }

      return data.group_join_request;
    } catch (error) {
      throw new Error(error?.response?.data?.message || "ERROR");
    }
  },

  async createGroup(body: {
    name: string;
    description: string;
    category: string;
    image: string;
    settings: {
      is_public: boolean;
      enforce_join_approval: boolean;
      allow_member_invite: boolean;
    };
  }) {
    try {
      const { data } = await api.post(`/social/api/group`, body);
      return data.group;
    } catch (error) {
      handleApiError(error);
    }
  },

  async getGroupInvitations(limit: number, key?: string) {
    try {
      const { data } = await api.get(
        `/social/api/group/invites?status=pending`,
        {
          params: {
            limit,
            key,
          },
        },
      );
      return data.group_invites;
    } catch (error) {
      throw new Error(error?.response?.data?.message || "ERROR");
    }
  },

  async getGroupJoinRequests(limit: number, key?: string) {
    try {
      const { data } = await api.get(
        `/social/api/group/join_requests?status=pending`,
        {
          params: {
            limit,
            key,
          },
        },
      );
      return data.group_join_requests;
    } catch (error) {
      throw new Error(error?.response?.data?.message || "ERROR");
    }
  },

  async putRespondInviteReq(
    groupId: string,
    inviteId: string,
    status: "accepted" | "rejected",
  ) {
    try {
      const { data } = await api.put(
        `/social/api/group/${groupId}/invite/${inviteId}`,
        { status },
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async putRespondJoinReq(
    groupId: string,
    requestId: string,
    status: "accepted" | "rejected",
  ) {
    try {
      const { data } = await api.put(
        `/social/api/group/${groupId}/join_request/${requestId}`,
        { status },
      );
      return data;
    } catch (error) {
      throw new Error(error?.response?.data?.message || "ERROR");
    }
  },

  async getPublicGroups(limit: number, key?: string) {
    try {
      const { data } = await api.get<{ groups: PublicGroup[] }>(
        `/social/api/group/trending`,
        {
          params: {
            limit,
            key,
          },
        },
      );
      return data?.groups;
    } catch (error) {
      handleApiError(error);
    }
  },

  async searchPublicGroups({
    limit,
    search,
    signal,
    key,
  }: FetchSearchPublicGroupsParams) {
    try {
      const { data } = await api.get(`/social/api/group/search_public_groups`, {
        params: {
          limit,
          key,
          search,
        },
        signal,
      });
      return data?.groups;
    } catch (error) {
      if (signal?.aborted) {
        return;
      }
      handleApiError(error);
    }
  },

  async getGroupCategories() {
    try {
      const { data } = await api.get<{ categories: string[] }>(
        `/social/api/group/categories`,
      );
      return data.categories;
    } catch (error) {
      handleApiError(error);
    }
  },
};

export const GAME_API = {
  async postCreateGame(body: {
    name: string;
    files: { id: string; source: "audio" | "document" }[];
    description: string;
    per_game_cost: number;
    winner_takes_all: boolean;
    settings: {
      max_questions: number;
      question_duration: number;
      allow_owner_participation: boolean;
    };
  }) {
    try {
      const { data } = await api.post<CreateGameResponse>(
        `/game/api/game`,
        body,
      );
      return data.game;
    } catch (error) {
      handleApiError(error);
    }
  },

  async getGames(limit: number, page: number) {
    try {
      const { data } = await api.get(`/game/api/game/joined`, {
        params: {
          limit,
          page,
        },
      });
      return data.joined_games;
    } catch (error) {
      handleApiError(error);
    }
  },

  async getLobbyMessages({ limit, key, gameId }: getLobbyMessagesProps) {
    try {
      const { data } = await api.get(`/game/api/game/${gameId}/messages?`, {
        params: {
          limit,
          key,
        },
      });
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async postSendMessage(
    gameId: string,
    body: {
      type: string;
      body?: string;
      parent?: string;
    },
  ) {
    try {
      const { data } = await api.post<{ game_message: MessageType }>(
        `/game/api/game/${gameId}/message`,
        body,
      );
      return data;
    } catch (error) {
      throw new Error(error?.response?.data?.message || "ERROR");
    }
  },

  async getMembers({ gameId, key, limit }: getMembersProps) {
    try {
      const { data } = await api.get<{ game_members: GameMemberType[] }>(
        `/game/api/game/${gameId}/members`,
        {
          params: {
            key,
            limit: limit ?? 10,
          },
        },
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async getRoomInfoByCode(code: string) {
    try {
      const { data } = await api.get<{ game: GameRoomType }>(
        `/game/api/game/info/${code}`,
        {},
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
  async postJoinGame(groupId: string) {
    try {
      const { data } = await api.post(`/game/api/game/${groupId}/join`);
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async postStartGame(groupId: string) {
    try {
      await api.post(`/game/api/game/${groupId}/start`);
    } catch (error) {
      handleApiError(error);
    }
  },

  async getGameByInvite(inviteCode: string) {
    try {
      const { data } = await api.get(`/game/api/game/info/${inviteCode}`);
      return data.game;
    } catch (error) {
      throw new Error(error?.response?.data?.message || "ERROR");
    }
  },
  async getGameById(gameId: string) {
    try {
      const { data } = await api.get(`/game/api/game/${gameId}/info`);
      return data.game;
    } catch (error) {
      throw new Error(error?.response?.data?.message || "ERROR");
    }
  },

  async postGameSubmission(gameId: string, body: GameSubmissionBody) {
    try {
      const { data } = await api.post(
        `/game/api/game/${gameId}/submissions`,
        body,
      );
      return data.game;
    } catch (error) {
      handleApiError(error);
    }
  },

  async getGameResults(gameId: string, limit: number, page?: number) {
    try {
      const { data } = await api.get<{ leaderboard: GameResults }>(
        `game/api/game/${gameId}/leaderboard`,
        {
          params: {
            limit,
            page,
          },
        },
      );
      return data.leaderboard;
    } catch (error) {
      handleApiError(error);
    }
  },

  async exitGame(gameId: string) {
    try {
      await api.put(`/game/api/game/${gameId}/exit`);
    } catch (error) {
      throw new Error(error?.response?.data?.message || "ERROR");
    }
  },

  async deleteGame(gameId: string) {
    try {
      await api.delete(`/game/api/game/${gameId}`);
    } catch (error) {
      throw new Error(error?.response?.data?.message || "ERROR");
    }
  },

  async putGameSettings(gameId: string, body: GameSettingsBody) {
    try {
      const { data } = await api.put(`/game/api/game/${gameId}`, body);
      return data;
    } catch (error) {
      throw new Error(error?.response?.data?.message || "ERROR");
    }
  },

  async getResultsHistory(gameId: string, limit: number, key?: string) {
    try {
      const { data } = await api.get(`/game/api/game/${gameId}/quizzes`, {
        params: {
          limit,
          key,
        },
      });
      return data.game_quizzes;
    } catch (error) {
      handleApiError(error);
    }
  },
  async leaveGame(gameId: string, quizId: string) {
    try {
      const { data } = await api.post(
        `/game/api/game/${gameId}/${quizId}/leave`,
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
};

export const ANDY_API = {
  async postCreateSessionId(return_greeting = true) {
    try {
      const { data } = await api.post<{ session_id: string; greeting: string }>(
        `/andy/api/chat/session`,
        {
          return_greeting,
        },
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async postSubmitQuery({ query, session_id }) {
    try {
      const { data } = await api.post(`/andy/api/chat/${session_id}/query`, {
        query,
      });
      return data;
    } catch (error) {
      throw error;
    }
  },

  async getChatMessages({ session_id, limit = 10, key = undefined }) {
    try {
      const { data } = await api.get(`/andy/api/chat/${session_id}/messages`, {
        params: {
          limit,
          key,
        },
      });
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async postMessageFeedback({
    message_id,
    feedback,
  }: {
    message_id: string;
    feedback: FeedbackType;
  }) {
    try {
      await api.post(`/andy/api/chat/${message_id}/feedback`, {
        feedback,
      });
    } catch (error) {
      handleApiError(error);
    }
  },

  async getChatSessions({ limit, key }: { limit: number; key?: string }) {
    try {
      const { data } = await api.get(`/andy/api/chat/sessions`, {
        params: {
          limit,
          key,
        },
      });

      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async getQuestionsOfTheDay() {
    try {
      const { data } = await api.get("/user/api/account/configuration", {
        params: { keyname: "questions_of_the_day" },
      });
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async getStats() {
    try {
      const { data } = await api.get<GetStats>("/user/api/account/query/stats");
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },

  async getCreditStats() {
    try {
      const { data } = await api.get<GetCreditStats>(
        "/user/api/account/credit/stats",
      );
      return data;
    } catch (error) {
      handleApiError(error);
    }
  },
};
