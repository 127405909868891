import { ReactNode } from "react";
import ContentLoader from "react-content-loader";

import { useAppSelector } from "../hooks/redux-hooks";
import { selectTheme } from "../Slice/userSlice";

interface SkeletonWrapperProps {
  children: ReactNode;
  width: string;
  height: string;
  viewBox?: string;
  className?: string;
  variant?: "light" | "dark";
}

const SkeletonWrapper = ({
  children,
  width,
  height,
  viewBox,
  className,
  variant,
}: SkeletonWrapperProps) => {
  const { isLinked, isLightTheme } = useAppSelector(selectTheme);
  const isLight = variant ? variant === "light" : isLinked || isLightTheme;

  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={viewBox}
      backgroundColor={isLight ? "#d6d6d6" : "#383838"}
      foregroundColor={isLight ? "#bababa" : "#666666"}
      className={className}
    >
      {children}
    </ContentLoader>
  );
};

export default SkeletonWrapper;
