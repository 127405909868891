import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Room, fetchRoomsProps } from "../types";
import { toast } from "react-toastify";
import { DM_API } from "../api/requests";
import { RootState } from "../Store/store";

const ROOMS_LIMIT = 15;

interface dmSliceState {
  rooms: Room[];
  page: number;
  hasMoreRooms: boolean;
  loading: boolean;
  forwardMessageRooms: Room[];
  forwardMessageLoading: boolean;
  forwardMessageHasMoreRooms: boolean;
  forwardMessagePage: number;
  hasAvailableRooms: null | boolean;
  currentRoom: Room | null;
}

const initialState: dmSliceState = {
  rooms: [],
  page: 1,
  hasMoreRooms: true,
  loading: false,
  forwardMessageRooms: [],
  forwardMessageLoading: false,
  forwardMessageHasMoreRooms: true,
  forwardMessagePage: 1,
  hasAvailableRooms: null,
  currentRoom: null,
};

export const dmSlice = createSlice({
  name: "direct",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setHasAvailableRooms(state, action: PayloadAction<boolean>) {
      state.hasAvailableRooms = action.payload;
    },
    setForwardMessageLoading(state, action: PayloadAction<boolean>) {
      state.forwardMessageLoading = action.payload;
    },
    setPageRooms(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setForwardMessagePage(state, action: PayloadAction<number>) {
      state.forwardMessagePage = action.payload;
    },
    setRooms(state, action: PayloadAction<Room[]>) {
      state.rooms = action.payload;
    },
    setForwardMessageRooms(state, action: PayloadAction<Room[]>) {
      state.forwardMessageRooms = action.payload;
    },
    setHasMoreRooms(state, action: PayloadAction<boolean>) {
      state.hasMoreRooms = action.payload;
    },
    setForwardMessageHasMoreRooms(state, action: PayloadAction<boolean>) {
      state.forwardMessageHasMoreRooms = action.payload;
    },
    updateRoomsList(state, action: PayloadAction<Room>) {
      const hasRoom = state.rooms.some(
        (room) =>
          room.receiver_meta.user_id === action.payload.receiver_meta.user_id
      );
      const updatedRooms = hasRoom
        ? state.rooms.filter(
            (room) =>
              room?.receiver_meta?.user_id !==
              action.payload.receiver_meta.user_id
          )
        : state.rooms.slice(0, -1);
      state.rooms = [action.payload, ...updatedRooms];
    },
    setCurrentRoom(state, action: PayloadAction<Room | null>) {
      state.currentRoom = action.payload;
    },
  },
});

export const fetchRooms = ({
  page = 1,
  debouncedInput,
  signal,
  isForwardMessage,
}: fetchRoomsProps): any => {
  return async (dispatch, getState: () => RootState) => {
    const previousRooms = isForwardMessage
      ? getState().direct.forwardMessageRooms
      : getState().direct.rooms;
    const hasAvailableRooms = getState().direct.hasAvailableRooms;
    const isFirstPage = page === 1;

    if (isFirstPage) {
      if (isForwardMessage) {
        dispatch(setForwardMessageLoading(true));
        dispatch(setForwardMessageHasMoreRooms(true));
      } else {
        dispatch(setLoading(true));
        dispatch(setHasMoreRooms(true));
      }
    }

    try {
      if (debouncedInput) {
        const result = await DM_API.getRooms({
          search: debouncedInput,
          signal,
        });
        if (result) {
          if (isForwardMessage) {
            dispatch(setForwardMessageHasMoreRooms(false));
            dispatch(setForwardMessageRooms(result));
            dispatch(setForwardMessageLoading(false));
          } else {
            dispatch(setHasMoreRooms(false));
            dispatch(setRooms(result));
            dispatch(setLoading(false));
          }
        }
        return;
      }
      const res = await DM_API.getRooms({
        page,
        limit: ROOMS_LIMIT,
      });
      if (hasAvailableRooms === null) {
        dispatch(setHasAvailableRooms(Boolean(res?.length)));
      }

      const newRooms = isFirstPage ? res : [...previousRooms, ...res];

      const hasMoreRooms = res?.length === ROOMS_LIMIT;

      if (isForwardMessage) {
        dispatch(setForwardMessageHasMoreRooms(hasMoreRooms));
        dispatch(setForwardMessageRooms(newRooms));
        dispatch(setForwardMessagePage(page + 1));
      } else {
        dispatch(setRooms(newRooms));
        dispatch(setHasMoreRooms(hasMoreRooms));
        dispatch(setPageRooms(page + 1));
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "ERROR");
    } finally {
      dispatch(setForwardMessageLoading(false));
      dispatch(setLoading(false));
    }
  };
};

export const {
  setLoading,
  setHasAvailableRooms,
  setForwardMessageLoading,
  setPageRooms,
  setForwardMessagePage,
  setRooms,
  setForwardMessageRooms,
  setHasMoreRooms,
  setForwardMessageHasMoreRooms,
  updateRoomsList,
  setCurrentRoom,
} = dmSlice.actions;
export default dmSlice.reducer;
